import React, { useState } from 'react';
import {
  WarningIcon,
  Box,
  Alert,
  PageContent,
  useTheme,
} from '@cherre-frontend/ui';
import {
  DragDropUpload,
  DragDropUploadProps,
} from './components/DragDropUpload';

export type IngestUploadFileStepProps<T> = {
  onComplete: DragDropUploadProps<T>['onComplete'];
  sendFile: DragDropUploadProps<T>['sendFile'];
  isLoading?: boolean;
};

export function IngestUploadFileStep<T>({
  onComplete,
  sendFile,
  isLoading,
}: IngestUploadFileStepProps<T>) {
  const [error, setError] = useState<string[]>([]);
  const theme = useTheme();
  return (
    <PageContent>
      {(error || []).length > 0 ? (
        <Box
          sx={{
            borderRadius: '10px',
            overflow: 'hidden',
            height: '80px',
          }}
        >
          {(error || []).map((error_message, index) => (
            <Alert
              key={index}
              icon={<WarningIcon fontSize='small' color='primary' />}
              severity='error'
              sx={{
                backgroundColor: theme.palette.error.light,
              }}
            >
              <Box
                sx={{
                  fontWeight: 600,
                  fontSize: '14px',
                  color: theme.accents.error.main,
                }}
              >
                {error_message}
              </Box>
            </Alert>
          ))}
        </Box>
      ) : null}
      <DragDropUpload<T>
        sendFile={sendFile}
        onComplete={onComplete}
        SupportedFiles='csv,xls,xlsx'
        onError={(error: string[]) => setError(error)}
        isLoading={isLoading}
      />
    </PageContent>
  );
}
