import {
  constSelector,
  GraphQLReturn,
  graphQLSelector,
  useCherreValue,
  useCherreValueLoadable,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUnifiedIngestExtractDataQuery } from './__generated__/useUnifiedIngestExtractDataQuery.graphql';

const $unifiedIngestExtractData = graphQLSelector({
  query: graphql`
    query useUnifiedIngestExtractDataQuery(
      $params: unified_ingest_extract_data_params!
    ) {
      unified_ingest_extract_data(params: $params) {
        file_id
        owner_name
        json_data {
          record
          row_number
        }
      }
    }
  ` as GraphQLReturn<useUnifiedIngestExtractDataQuery>,
  mapResponse: (resp) => {
    return resp.unified_ingest_extract_data;
  },
});

export const useUnifiedIngestExtractData = (
  file_id?: string,
  owner_name?: string,
  opts: { limit: number; offset: number } = { limit: 100, offset: 0 }
) => {
  return useCherreValue(
    file_id && owner_name
      ? $unifiedIngestExtractData({
          params: {
            file_id,
            owner_name,
            limit: opts.limit,
            offset: opts.offset,
          },
        })
      : constSelector(null)
  );
};

export const useUnifiedIngestExtractDataLoadable = (
  file_id?: string,
  owner_name?: string,
  opts: { limit: number; offset: number } = { limit: 100, offset: 0 }
) => {
  return useCherreValueLoadable(
    file_id && owner_name
      ? $unifiedIngestExtractData({
          params: {
            file_id,
            owner_name,
            limit: opts.limit,
            offset: opts.offset,
          },
        })
      : constSelector(null)
  );
};
