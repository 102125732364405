import React from 'react';
import { Box, Button, HelpIcon, Typography } from '@cherre-frontend/ui';

export type HelpBoxProps = {
  title: string;
  subtitle: React.ReactNode;
  helpText: string;
  submitSiblingText?: string;
  submitText: string;
  submitDisabled?: boolean;
  onClickHelp?: () => void;
  onClickSubmitSibling?: () => void;
  onClickSubmit: () => void;
  onRefChange?: (node: HTMLDivElement) => void;
};

export const HelpBox: React.FC<HelpBoxProps> = ({
  title,
  subtitle,
  helpText,
  submitSiblingText,
  submitText,
  submitDisabled,
  onClickHelp,
  onClickSubmitSibling,
  onClickSubmit,
  onRefChange,
}) => {
  return (
    <Box
      ref={onRefChange}
      sx={{
        borderRadius: '10px',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        padding: '16px',
        width: '100%',
        marginBottom: '16px',
      }}
    >
      <Typography
        sx={{ fontSize: '16px', fontWeight: 700, marginBottom: '8px' }}
      >
        {title}
      </Typography>
      {typeof subtitle === 'string' ? (
        <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
          {subtitle}
        </Typography>
      ) : (
        subtitle
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        {onClickHelp ? (
          <Button variant='text' onClick={onClickHelp}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              <HelpIcon
                color='primary'
                sx={{ height: '20px', width: '20px' }}
              />
              <Typography sx={{ fontWeight: 600 }}>{helpText}</Typography>
            </Box>
          </Button>
        ) : (
          <span />
        )}
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
          {submitSiblingText && (
            <Button
              variant='text'
              onClick={onClickSubmitSibling}
              style={{ textTransform: 'unset', fontWeight: 600 }}
            >
              {submitSiblingText}
            </Button>
          )}
          <Button
            variant='contained'
            disabled={submitDisabled}
            onClick={onClickSubmit}
            style={{ width: '80px', fontWeight: 600 }}
          >
            {submitText}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
