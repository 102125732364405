import {
  constSelector,
  GraphQLReturn,
  graphQLSelector,
  useCherreValueLoadable,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUnifiedIngestDownloadColumnMappingQuery } from './__generated__/useUnifiedIngestDownloadColumnMappingQuery.graphql';

export const $unifiedIngestDownloadColumnMapping = graphQLSelector({
  query: graphql`
    query useUnifiedIngestDownloadColumnMappingQuery(
      $params: unified_ingest_column_mapping_data_params!
    ) {
      unified_ingest_column_mapping_data(params: $params) {
        json_data
      }
    }
  ` as GraphQLReturn<useUnifiedIngestDownloadColumnMappingQuery>,
  mapResponse: (resp) => {
    return resp.unified_ingest_column_mapping_data.json_data;
  },
});

export const useUnifiedIngestDownloadColumnMappingData = (
  file_id?: string,
  owner_name?: string,
  opts: { limit: number; offset: number } = { limit: 0, offset: 0 }
) => {
  const data = useCherreValueLoadable(
    file_id && owner_name
      ? $unifiedIngestDownloadColumnMapping({
          params: {
            file_id,
            owner_name,
            limit: opts.limit,
            offset: opts.offset,
          },
        })
      : constSelector([])
  );

  return {
    data,
  };
};
