/**
 * @generated SignedSource<<084f3a328bf95b204ed0d71bcf319953>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type unified_ingest_column_mapping_set_params = {
  dataset_id: string;
  file_id: string;
  owner_name: string;
  use_cache: boolean;
  user_mapped_columns: ReadonlyArray<any>;
};
export type useUnifiedIngestSetColumnMappingMutation$variables = {
  params: unified_ingest_column_mapping_set_params;
};
export type useUnifiedIngestSetColumnMappingMutation$data = {
  readonly unified_ingest_column_mapping_set: {
    readonly file_id: string;
    readonly owner_name: string;
  };
};
export type useUnifiedIngestSetColumnMappingMutation = {
  response: useUnifiedIngestSetColumnMappingMutation$data;
  variables: useUnifiedIngestSetColumnMappingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "unified_ingest_column_mapping_set_output",
    "kind": "LinkedField",
    "name": "unified_ingest_column_mapping_set",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "file_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "owner_name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUnifiedIngestSetColumnMappingMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUnifiedIngestSetColumnMappingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b8ebbfab86a2168f3688db80e9bb5862",
    "id": null,
    "metadata": {},
    "name": "useUnifiedIngestSetColumnMappingMutation",
    "operationKind": "mutation",
    "text": "mutation useUnifiedIngestSetColumnMappingMutation(\n  $params: unified_ingest_column_mapping_set_params!\n) {\n  unified_ingest_column_mapping_set(params: $params) {\n    file_id\n    owner_name\n  }\n}\n"
  }
};
})();

(node as any).hash = "637910ce8c2eed616d16513107920b44";

export default node;
