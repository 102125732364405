import React, { useCallback, useMemo } from 'react';
import {
  Table,
  useTable,
  styled,
  useTheme,
  Box,
  Typography,
  InfoIcon,
  DashedArrowIcon,
  MuiAutocomplete as Autocomplete,
  TextField,
  ErrorIcon,
  CheckCircleIcon,
  RemoveCircleIcon,
  Tooltip,
} from '@cherre-frontend/ui';
import { useUnifiedIngestGetColumnMappingQuery } from '../../../../../services/__generated__/useUnifiedIngestGetColumnMappingQuery.graphql';
import { useUnifiedIngestGetTransformedDataQuery } from '../../../../../services/__generated__/useUnifiedIngestGetTransformedDataQuery.graphql';
import { useUnifiedIngestGetTargetFieldsQuery } from 'src/products/data-submission-portal/pages/upload-dataset-new/queries/__generated__/useUnifiedIngestGetTargetFieldsQuery.graphql';
import { useIsSuspended } from '@cherre-frontend/data-fetching';

const Container = styled('div')`
  padding-top: 20px;
  display: flex;
  flex: 1;
`;

export type ColumnMappingSheetProps = {
  dataset: string;
  data: useUnifiedIngestGetTransformedDataQuery['response']['unified_ingest_transform_data']['transformed_data'];
  columnMappingState: {
    source: string;
    target: string;
    option: { title: string; group: string } | null;
  }[];
  setColumnMappingState: React.Dispatch<
    React.SetStateAction<
      {
        source: string;
        target: string;
        option: { title: string; group: string } | null;
      }[]
    >
  >;
  setDataPreviewColumn: React.Dispatch<React.SetStateAction<string>>;
  targetFields?: useUnifiedIngestGetTargetFieldsQuery['response']['sys_property_batch_datasets'][0]['dataset'];
  columnSuggestions: useUnifiedIngestGetColumnMappingQuery['response']['unified_ingest_column_mapping_get']['mapped_columns'];
};

export const ColumnMappingSheet: React.FC<ColumnMappingSheetProps> = ({
  data,
  dataset,
  columnMappingState,
  setColumnMappingState,
  setDataPreviewColumn,
  targetFields,
  columnSuggestions,
}) => {
  const theme = useTheme();

  const getOptions = useCallback(
    (targetField: string) => {
      if (columnSuggestions.length) {
        const sourceSuggestions = columnSuggestions
          .filter((suggestion) => suggestion.targetFilledName === targetField)
          .map((column) => column.name);
        return Object.keys(data[0])
          .map((column) => ({
            title: column as string,
            group: sourceSuggestions.includes(column as string)
              ? 'Suggested'
              : 'Not Mapped',
          }))
          .filter((opt) => {
            return (
              !columnMappingState
                .map((row) => row.source)
                .includes(opt.title) ||
              columnMappingState.find((row) => row.target === targetField)
                ?.source === opt.title
            );
          })
          .sort((a, b) =>
            a.group === b.group ? 0 : a.group === 'Suggested' ? -1 : 1
          );
      } else {
        return Object.keys(data[0])
          .map((column) => ({
            title: column as string,
            group: 'Not Mapped',
          }))
          .filter(
            (opt) =>
              !columnMappingState.map((row) => row.source).includes(opt.title)
          );
      }
    },
    [columnSuggestions, columnMappingState]
  );

  const getTargetFieldIcon = useCallback(
    (name: string, mandatory: boolean) => {
      const field = columnMappingState.find((row) => row.target === name);
      if (mandatory && !field?.source) {
        return (
          <Tooltip title='This target field is required and needs to be mapped.'>
            <ErrorIcon
              sx={{
                height: '18px',
                width: '18px',
                color: theme.accents.error.main,
              }}
            />
          </Tooltip>
        );
      } else if (field?.source) {
        return (
          <Tooltip title='This target field is mapped.'>
            <CheckCircleIcon
              sx={{
                height: '18px',
                width: '18px',
                color: theme.palette.success.dark,
              }}
            />
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title='This target field is not required.'>
            <RemoveCircleIcon
              sx={{
                height: '18px',
                width: '18px',
                color: theme.palette.grey[500],
              }}
            />
          </Tooltip>
        );
      }
    },
    [columnMappingState]
  );

  const isSuspended = useIsSuspended();

  const wordsToUppercase = ['gl', 'id'];

  const columns = useMemo(
    () => [
      {
        accessorKey: 'source',
        header: 'Columns in Your File',
        Header: ({ column }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Tooltip
              title={`Select columns from your source file to map to the target fields for the ${dataset} dataset.`}
            >
              <InfoIcon sx={{ height: '18px', width: '18px' }} />
            </Tooltip>
            <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
              {column.columnDef.header}
            </Typography>
          </Box>
        ),
        Cell: ({ row }) => {
          if (isSuspended) {
            return null;
          }

          return (
            <Autocomplete
              value={
                columnMappingState.find(
                  (tableRow) => tableRow.target === row.original.name
                )?.option ?? null
              }
              isOptionEqualToValue={(option, value) => {
                return option.title === value.title;
              }}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  color='primary'
                  placeholder='Search or select'
                  variant='standard'
                />
              )}
              onChange={(_, value) => {
                setColumnMappingState((prev) => {
                  const newState = [...prev];
                  const index = newState.findIndex(
                    (tableRow) => tableRow.target === row.original.name
                  );
                  newState[index].source = value?.title ?? '';
                  newState[index].option = value ?? null;
                  return newState;
                });
              }}
              options={getOptions(row.original.name)}
              getOptionLabel={(option) => option.title}
              groupBy={(option) => option.group}
              renderGroup={(params) => (
                <li key={params.key}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 600,
                        textTransform: 'uppercase',
                        color: theme.palette.grey[500],
                        paddingLeft: '8px',
                      }}
                    >
                      {params.group}
                    </Typography>
                  </Box>
                  <ul style={{ padding: '0px', margin: '0px' }}>
                    {params.children}
                  </ul>
                </li>
              )}
            />
          );
        },
        grow: true,
        muiTableBodyCellProps: ({ row }) => ({
          onMouseEnter: () => {
            const sourceColumn = columnMappingState.find(
              (tableRow) => tableRow.target === row.original.name
            )?.source;
            setDataPreviewColumn(sourceColumn ?? '');
          },
          onMouseLeave: () => {
            setDataPreviewColumn('');
          },
        }),
      },
      {
        header: '',
        Header: () => (
          <Box>
            <DashedArrowIcon
              sx={{ height: '15px', width: '15px', color: 'white' }}
            />
          </Box>
        ),
        accessorKey: 'arrow',
        Cell: () => (
          <Box>
            <DashedArrowIcon sx={{ height: '15px', width: '15px' }} />
          </Box>
        ),
        grow: false,
        muiTableBodyCellProps: ({ row }) => ({
          sx: {
            maxWidth: '45px',
            minWidth: 'unset',
            backgroundColor: 'white',
            border: '0px',
          },
          onMouseEnter: () => {
            const sourceColumn = columnMappingState.find(
              (tableRow) => tableRow.target === row.original.name
            )?.source;
            setDataPreviewColumn(sourceColumn ?? '');
          },
          onMouseLeave: () => {
            setDataPreviewColumn('');
          },
        }),
        muiTableHeadCellProps: () => ({
          sx: {
            maxWidth: '45px',
            minWidth: 'unset',
            backgroundColor: theme.palette.secondary.main,
          },
        }),
      },
      {
        accessorKey: 'name',
        header: 'Target Fields',
        Header: ({ column }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Tooltip
              title={`Target fields from the ${dataset} dataset that source file columns should be mapped to.`}
            >
              <InfoIcon sx={{ height: '18px', width: '18px' }} />
            </Tooltip>
            <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
              {column.columnDef.header}
            </Typography>
          </Box>
        ),
        grow: true,
        Cell: ({ row }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            {getTargetFieldIcon(row.original.name, row.original.mandatory)}
            <Typography
              sx={{
                '&::after': {
                  content: row.original.mandatory ? "'*'" : "''",
                  color: 'red',
                  marginLeft: '2px',
                },
              }}
            >
              {row.original.name
                .split('_')
                .map((word: string) =>
                  wordsToUppercase.includes(word)
                    ? word.toUpperCase()
                    : `${word.charAt(0).toUpperCase()}${word.substring(1)}`
                )
                .join(' ')}
            </Typography>
          </Box>
        ),
        muiTableBodyCellProps: ({ row }) => ({
          onMouseEnter: () => {
            const sourceColumn = columnMappingState.find(
              (tableRow) => tableRow.target === row.original.name
            )?.source;
            setDataPreviewColumn(sourceColumn ?? '');
          },
          onMouseLeave: () => {
            setDataPreviewColumn('');
          },
        }),
      },
    ],
    [
      columnMappingState,
      targetFields,
      columnSuggestions,
      getTargetFieldIcon,
      getOptions,
      isSuspended,
    ]
  );

  const sortedRows = useMemo(
    () =>
      [...targetFields?.unified_ingest_schemas.original].sort((a, b) => {
        return a.mandatory === b.mandatory ? 0 : a.mandatory ? -1 : 1;
      }),
    [targetFields]
  );

  const table = useTable({
    data: sortedRows,
    layoutMode: 'grid',
    skeletonRowCount: 5,
    columns: columns,
    enableSorting: false,
    enableColumnResizing: false,
    enableRowSelection: false,
    enableRowActions: false,
    enableBottomToolbar: false,
    enablePagination: false,
    positionActionsColumn: 'last',
    muiTableProps: {
      sx: {
        height: '100%',
      },
    },
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
        borderRadius: '16px 16px 16px 16px',
        maxHeight: '800px',
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
        border: `1px ${theme.palette.grey[300]} solid`,
        borderRadius: '16px',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        backgroundColor: 'white',
        border: '0px',
      },
    },
    muiTableBodyRowProps: {
      sx: {
        '&:hover td': {
          backgroundColor: theme.palette.grey[200],
        },
      },
    },
    manualPagination: false,
    manualSorting: false,
    getRowId: (row) => row.name?.toString(),
    renderEmptyRowsFallback: () => <Box>No columns to map</Box>,
  });

  return (
    <Container sx={{ width: '100%', paddingBottom: '20px', paddingTop: '0px' }}>
      <Table table={table} />
    </Container>
  );
};
