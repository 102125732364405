/**
 * @generated SignedSource<<90e7cbfbed0428b001a8b9ee2c046774>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type unified_ingest_extract_file_params = {
  file_id: string;
  owner_name: string;
  worksheet_name: string;
};
export type useUnifiedIngestExtractFileMutation$variables = {
  params: unified_ingest_extract_file_params;
};
export type useUnifiedIngestExtractFileMutation$data = {
  readonly unified_ingest_extract_file: {
    readonly file_id: string;
    readonly owner_name: string;
  };
};
export type useUnifiedIngestExtractFileMutation = {
  response: useUnifiedIngestExtractFileMutation$data;
  variables: useUnifiedIngestExtractFileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "unified_ingest_extract_file_output",
    "kind": "LinkedField",
    "name": "unified_ingest_extract_file",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "file_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "owner_name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUnifiedIngestExtractFileMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUnifiedIngestExtractFileMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c7633d2b375f355ce2580632e194207b",
    "id": null,
    "metadata": {},
    "name": "useUnifiedIngestExtractFileMutation",
    "operationKind": "mutation",
    "text": "mutation useUnifiedIngestExtractFileMutation(\n  $params: unified_ingest_extract_file_params!\n) {\n  unified_ingest_extract_file(params: $params) {\n    file_id\n    owner_name\n  }\n}\n"
  }
};
})();

(node as any).hash = "0d7b55f5611145a4a00b1a8d4e39db0a";

export default node;
