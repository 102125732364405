import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUnifiedIngestUploadCompleteQuery } from './__generated__/useUnifiedIngestUploadCompleteQuery.graphql';

export const $unifiedIngestUploadComplete = graphQLSelector({
  query: graphql`
    query useUnifiedIngestUploadCompleteQuery(
      $params: unified_ingest_upload_complete_params!
    ) {
      unified_ingest_upload_complete(params: $params) {
        owner_name
        upload_complete
        file_size
      }
    }
  ` as GraphQLReturn<useUnifiedIngestUploadCompleteQuery>,
  mapResponse: (resp) => {
    return resp.unified_ingest_upload_complete;
  },
});
