import React from 'react';
import { PageContent } from '@cherre-frontend/ui';
import { HelpBox } from '../HelpBox';
import { HeaderSelectionTable } from './components/HeaderSelectionTable';

export type IngestHeaderSelectionStepProps = {
  onClickSubmit: () => void;
  selectedHeaderRows?: readonly number[];
  setSelectedHeaderRows: React.Dispatch<
    React.SetStateAction<readonly number[] | undefined>
  >;
  fileId: string;
  templateId: string | null;
  data?: {
    record: string[];
    row_number: number;
  }[];
};

export const IngestHeaderSelectionStep: React.FC<
  IngestHeaderSelectionStepProps
> = ({
  onClickSubmit,
  selectedHeaderRows,
  setSelectedHeaderRows,
  templateId,
  data,
}) => {
  return (
    <>
      <HelpBox
        title='Select the column header row'
        subtitle='Select the column header row for the uploaded file below if needed before we review and finalize.'
        helpText="Doesn't seem right? Ask Cherre for help."
        submitText='Next'
        submitDisabled={selectedHeaderRows?.length === 0}
        onClickHelp={
          window.INTERCOM_APP_ID
            ? () => {
                window.Intercom('showNewMessage');
              }
            : undefined
        }
        onClickSubmit={onClickSubmit}
      />
      <PageContent>
        <HeaderSelectionTable
          data={data}
          templateId={templateId}
          selectedHeaderRows={selectedHeaderRows}
          setSelectedHeaderRows={setSelectedHeaderRows}
        />
      </PageContent>
    </>
  );
};
