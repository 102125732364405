import { useRef } from 'react';
import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUnifiedIngestUploadCreateSignedUrlMutation } from './__generated__/useUnifiedIngestUploadCreateSignedUrlMutation.graphql';
import { useErrorRef } from '@cherre-frontend/ui';

export const useUnifiedIngestUploadCreateSignedUrl = () => {
  const ref = useRef<
    useUnifiedIngestUploadCreateSignedUrlMutation['response'] | null
  >(null);
  const { errorRef, setError } = useErrorRef();

  const signedUrl = useMutation(
    graphql`
      mutation useUnifiedIngestUploadCreateSignedUrlMutation(
        $params: unified_ingest_upload_create_signed_url_params!
      ) {
        unified_ingest_upload_create_signed_url(params: $params) {
          file_id
          upload_url
        }
      }
    ` as GraphQLReturn<useUnifiedIngestUploadCreateSignedUrlMutation>,
    {
      onCompleted: (resp) => {
        ref.current = resp;
      },
      onError: (error, ctx) => {
        ctx.showSnackbar({
          message: 'Error creating signed URL',
          type: 'error',
        });
        setError(error);
      },
    }
  );

  return async (
    params: useUnifiedIngestUploadCreateSignedUrlMutation['variables']
  ) => {
    await signedUrl(params);
    if (errorRef.current) {
      throw errorRef.current;
    }
    return ref.current?.unified_ingest_upload_create_signed_url;
  };
};
