/**
 * @generated SignedSource<<32c0160c3ab66d2f06fd0fdee7cf1924>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type unified_ingest_column_mapping_data_params = {
  file_id: string;
  limit: number;
  offset: number;
  owner_name: string;
};
export type useUnifiedIngestDownloadColumnMappingQuery$variables = {
  params: unified_ingest_column_mapping_data_params;
};
export type useUnifiedIngestDownloadColumnMappingQuery$data = {
  readonly unified_ingest_column_mapping_data: {
    readonly json_data: ReadonlyArray<any>;
  };
};
export type useUnifiedIngestDownloadColumnMappingQuery = {
  response: useUnifiedIngestDownloadColumnMappingQuery$data;
  variables: useUnifiedIngestDownloadColumnMappingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "unified_ingest_column_mapping_data_output",
    "kind": "LinkedField",
    "name": "unified_ingest_column_mapping_data",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "json_data",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUnifiedIngestDownloadColumnMappingQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUnifiedIngestDownloadColumnMappingQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "20cc2126aaa3d9fc70452c42f5241c82",
    "id": null,
    "metadata": {},
    "name": "useUnifiedIngestDownloadColumnMappingQuery",
    "operationKind": "query",
    "text": "query useUnifiedIngestDownloadColumnMappingQuery(\n  $params: unified_ingest_column_mapping_data_params!\n) {\n  unified_ingest_column_mapping_data(params: $params) {\n    json_data\n  }\n}\n"
  }
};
})();

(node as any).hash = "8714469c98fe3c0a9ba61d870c531877";

export default node;
