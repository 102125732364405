import React, { useCallback, useLayoutEffect, useMemo } from 'react';
import {
  $unifiedIngestGetColumnMappingData,
  useUnifiedIngestExtractFile,
  useUnifiedIngestExtractWorksheets,
  useUnifiedIngestHeaderSet,
  useUnifiedIngestSendFile,
  useUnifiedIngestSetColumnMapping,
  useUnifiedIngestTransformFile,
  useUnifiedIngestUploadCreateSignedUrl,
  useUnifiedIngestHeaderGet,
  $unifiedIngestUploadComplete,
} from 'src/products/ingestion/services';
import {
  IngestionFlowActiveStepKey,
  IngestionFlowStepServiceProps,
} from '../../IngestionFlow';
import { IngestUploadFileStep } from './IngestUploadFileStep';
import { DragDropUploadFiles } from './components/DragDropUpload';
import { IngestSheetSelectionStep } from './components/IngestSheetSelectionStep';
import {
  useCherreEventWithRecoil,
  useRecoilScope,
} from '@cherre-frontend/data-fetching';
import { useRecoilCallback } from 'recoil';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useAppContext } from '@cherre-frontend/core';
export type IngestUploadFileStepServiceProps = IngestionFlowStepServiceProps;

export const IngestUploadFileStepService: React.FC<
  IngestUploadFileStepServiceProps
> = ({ flowState, setFlowState, nextStepKey, config }) => {
  const createUploadUrl = useUnifiedIngestUploadCreateSignedUrl();
  const uploadFile = useUnifiedIngestSendFile();
  const extractFile = useUnifiedIngestExtractFile();
  const setHeaderFile = useUnifiedIngestHeaderSet();
  const getHeaderFile = useUnifiedIngestHeaderGet();
  const transformFile = useUnifiedIngestTransformFile();
  const setColumnMapping = useUnifiedIngestSetColumnMapping();
  const context = useAppContext();
  const isTargetTableCreationEnabled = useFeatureFlag('TargetTableCreation');

  const { worksheets, isLoading } = useUnifiedIngestExtractWorksheets(
    flowState.fileId,
    config.ownerName
  );

  const onSheetSelectContinue = useCherreEventWithRecoil(
    'onSheetSelectContinue',
    (ctx) => async (selectedSheet: string) => {
      if (!flowState.fileId) {
        throw new Error('No file ID or sheet selected');
      }

      await extractFile({
        params: {
          file_id: flowState.fileId,
          owner_name: config.ownerName,
          worksheet_name: selectedSheet,
        },
      });

      //! This the code path that should stay when the feature flag is removed
      let selectedHeaderRows: readonly number[] =
        config.templateId === 'None' ? [1] : [];
      if (isTargetTableCreationEnabled) {
        const header = await getHeaderFile({
          params: {
            file_id: flowState.fileId,
            owner_name: config.ownerName,
            template_id:
              //TODO: @gaspardetienne97 refactor this so that templateId is undefined and not 'None' when it is structured ingest
              config.templateId !== 'None' ? config.templateId : '',
          },
        });

        if (!header || !header.header_rows) {
          throw new Error('No header rows found');
        }
        selectedHeaderRows = header.header_rows.map(
          (rowNumber) => rowNumber + 1
        );
      }
      //! This path can be removed when the feature flag is removed
      if (!isTargetTableCreationEnabled && config.templateId !== 'None') {
        const header = await getHeaderFile({
          params: {
            file_id: flowState.fileId,
            owner_name: config.ownerName,
            template_id: config.templateId,
          },
        });

        if (!header || !header.header_rows) {
          throw new Error('No header rows found');
        }
        // Set header rows only after user has selected

        await setHeaderFile({
          params: {
            file_id: flowState.fileId,
            owner_name: config.ownerName,
            header_rows: header.header_rows,
          },
        });

        try {
          await transformFile({
            params: {
              file_id: flowState.fileId,
              owner_name: config.ownerName,
              template_id:
                config.templateId === 'None' ? '' : config.templateId,
            },
          });

          const suggestions = await ctx.recoil
            .getPromise(
              $unifiedIngestGetColumnMappingData({
                params: {
                  file_id: flowState.fileId,
                  owner_name: config.ownerName,
                  target_schema: config.targetSchema,
                  dataset_id: config.datasetId,
                  template_id: config.templateId,
                  use_cache: false,
                },
              })
            )
            .catch((err) => {
              throw new Error(err.message);
            });

          const missingMandatoryColumn = config.targetSchema.find(
            (c) =>
              c.mandatory &&
              !suggestions.find((s) => s.targetFilledName === c.name)
          );

          if (missingMandatoryColumn) {
            throw new Error(`Missing ${missingMandatoryColumn.label} column`);
          }

          await setColumnMapping({
            params: {
              file_id: flowState.fileId,
              owner_name: config.ownerName,
              user_mapped_columns: suggestions.filter(
                (s) => s.targetFilledName !== null
              ),
              dataset_id: config.datasetId,
              use_cache: false,
            },
          });
        } catch (error: unknown) {
          if (error instanceof Error) {
            ctx.showSnackbar({
              message: error?.message || 'Error extracting the file',
              type: 'error',
            });
          }
          return;
        }
      }

      setFlowState((s) => ({
        ...s,
        activeStepKey: nextStepKey as IngestionFlowActiveStepKey,
        selectedSheet,
        selectedHeaderRows,
      }));
    },
    { timeout: false }
  );

  const sheets = useMemo(
    () =>
      worksheets?.map((sheet) => ({
        name: sheet,
        value: sheet,
      })),
    [worksheets]
  );

  const scope = useRecoilScope();

  const onCompleteFileUpload = useRecoilCallback(
    ({ snapshot }) =>
      async (fileId: string, fileSizes: number[]) => {
        const { upload_complete, file_size } = await snapshot.getPromise(
          $unifiedIngestUploadComplete({
            params: { file_id: fileId, owner_name: config.ownerName },
          })(scope)
        );

        if (upload_complete && file_size === fileSizes[0]) {
          setFlowState((s) => ({ ...s, fileId }));
        } else {
          const error = new Error('File upload failed');
          context.showSnackbar({
            type: 'error',
            message: error.message,
          });
          console.error(error);
          throw error;
        }
      },
    [config.ownerName]
  );

  const sendFile = useCallback(
    async (files: DragDropUploadFiles) => {
      const file = files instanceof Array ? files[0].file : files.file;
      const uploadUrlResponse = await createUploadUrl({
        params: { file_name: file.name, owner_name: config.ownerName },
      });

      if (!uploadUrlResponse) {
        throw new Error('No upload URL response');
      }

      await uploadFile(file, uploadUrlResponse.upload_url);

      return uploadUrlResponse.file_id;
    },
    [config.ownerName]
  );

  const onCancelSheetSelection = useCallback(() => {
    setFlowState((s) => ({ ...s, fileId: undefined }));
  }, []);

  const setSelectedSheet = useCallback(
    (selectedSheet: React.SetStateAction<string>) => {
      setFlowState((s) => ({
        ...s,
        selectedSheet:
          typeof selectedSheet === 'function'
            ? selectedSheet(s.selectedSheet ?? '')
            : selectedSheet,
      }));
    },
    []
  );

  const setFirstWorksheet =
    !!flowState.fileId && !!worksheets && worksheets.length === 1;

  useLayoutEffect(() => {
    if (setFirstWorksheet) {
      onSheetSelectContinue(worksheets?.[0]);
    }
  }, [setFirstWorksheet]);

  if (flowState.fileId && sheets && sheets.length > 1) {
    return (
      <IngestSheetSelectionStep
        sheets={sheets}
        onCancel={onCancelSheetSelection}
        onContinue={async () =>
          await onSheetSelectContinue(flowState.selectedSheet ?? '')
        }
        setSelectedSheet={setSelectedSheet}
        selectedSheet={flowState.selectedSheet}
      />
    );
  }

  return (
    <IngestUploadFileStep
      onComplete={onCompleteFileUpload}
      sendFile={sendFile}
      isLoading={isLoading || setFirstWorksheet}
    />
  );
};
