/**
 * @generated SignedSource<<7bb488bcf7e460f9af1257645fb8cf80>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type unified_ingest_extract_data_params = {
  file_id: string;
  limit: number;
  offset: number;
  owner_name: string;
};
export type useUnifiedIngestExtractDataQuery$variables = {
  params: unified_ingest_extract_data_params;
};
export type useUnifiedIngestExtractDataQuery$data = {
  readonly unified_ingest_extract_data: {
    readonly file_id: string;
    readonly json_data: ReadonlyArray<{
      readonly record: ReadonlyArray<string>;
      readonly row_number: number;
    }>;
    readonly owner_name: string;
  };
};
export type useUnifiedIngestExtractDataQuery = {
  response: useUnifiedIngestExtractDataQuery$data;
  variables: useUnifiedIngestExtractDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "unified_ingest_extract_data_output",
    "kind": "LinkedField",
    "name": "unified_ingest_extract_data",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "file_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "owner_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "unified_ingest_extract_data_json",
        "kind": "LinkedField",
        "name": "json_data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "record",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "row_number",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUnifiedIngestExtractDataQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUnifiedIngestExtractDataQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b97f3755f38e51728595938edeac42cc",
    "id": null,
    "metadata": {},
    "name": "useUnifiedIngestExtractDataQuery",
    "operationKind": "query",
    "text": "query useUnifiedIngestExtractDataQuery(\n  $params: unified_ingest_extract_data_params!\n) {\n  unified_ingest_extract_data(params: $params) {\n    file_id\n    owner_name\n    json_data {\n      record\n      row_number\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6e7e84e2b35472c8753b7a027a2acea7";

export default node;
