import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUnifiedIngestTransformFileMutation } from './__generated__/useUnifiedIngestTransformFileMutation.graphql';
import { useErrorRef } from '@cherre-frontend/ui';

export const useUnifiedIngestTransformFile = () => {
  const { errorRef, setError } = useErrorRef();
  const mutation = useMutation(
    graphql`
      mutation useUnifiedIngestTransformFileMutation(
        $params: unified_ingest_transform_file_params!
      ) {
        unified_ingest_transform_file(params: $params) {
          file_id
          owner_name
        }
      }
    ` as GraphQLReturn<useUnifiedIngestTransformFileMutation>,
    {
      onError: (error, ctx) => {
        ctx.showSnackbar({
          message: 'Error extracting the file',
          type: 'error',
        });
        setError(error);
      },
      timeout: false,
    }
  );

  return async (params: useUnifiedIngestTransformFileMutation['variables']) => {
    await mutation(params);
    if (errorRef.current) {
      throw errorRef.current;
    }
    return true;
  };
};
