import React, { useCallback, useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  styled,
  PopUpMessage,
  SxProps,
  Theme,
  Box,
} from '@cherre-frontend/ui';

export type IngestStepperComponentProps = {
  nextStep?: () => void;
  previousStep?: () => void;
  activeStepLabel: string;
  nextStepLabel?: string;
  nextStepKey?: string;
  previousStepLabel?: string;
  previousStepKey?: string;
};

export type IngestStepperStep = {
  label: string;
  key: string;
  component: React.ComponentType<IngestStepperComponentProps>;
  showWarning?: boolean | undefined;
  warningTitle?: string | undefined;
  warningDescription?: string;
  onWarningContinue?: () => void;
};

export type IngestStepperProps = {
  steps: IngestStepperStep[];
  activeStepIndex: number;
  setActiveStepIndex: React.Dispatch<React.SetStateAction<number>>;
  containerSx?: SxProps<Theme>;
  stepperSx?: SxProps<Theme>;
};

const CustomButton = styled(Button)(() => ({
  '&:hover': {
    backgroundColor: '#df24671a',
  },
}));

export const IngestStepper: React.FC<IngestStepperProps> = ({
  steps,
  activeStepIndex,
  setActiveStepIndex,
  containerSx,
  stepperSx,
}) => {
  const [popUp, setPopUp] = useState({
    open: false,
    title: '',
    description: '',
    onSubmit: () => {},
    onClose: () => {},
  });

  const nextStep = useCallback(
    () => setActiveStepIndex((prevActiveStep) => prevActiveStep + 1),
    [setActiveStepIndex]
  );

  const previousStep = useCallback(
    () => setActiveStepIndex((prevActiveStep) => prevActiveStep - 1),
    [setActiveStepIndex]
  );

  const popUpClose = useCallback(() => {
    setPopUp((prev) => ({ ...prev, open: false }));
  }, []);

  const handleStepSelection = useCallback(
    (step: (typeof steps)[number], index: number) => {
      if (step.showWarning) {
        setPopUp({
          open: true,
          title: step.warningTitle || '',
          description: step.warningDescription || '',
          onSubmit: () => {
            popUpClose();
            step.onWarningContinue?.();
            setActiveStepIndex(index);
          },
          onClose: popUpClose,
        });
      } else {
        setActiveStepIndex(index);
      }
    },
    [setActiveStepIndex]
  );

  if (!steps[activeStepIndex]) {
    return null;
  }

  const StepComponent = steps[activeStepIndex]?.component;

  return (
    <>
      <PopUpMessage {...popUp} />
      <Box sx={containerSx}>
        <Stepper
          activeStep={activeStepIndex}
          sx={{
            paddingLeft: '40px',
            paddingRight: '40px',
            paddingTop: '20px',
            paddingBottom: '20px',
            '& .MuiStepIcon-text': { fontSize: '12px' },
            '& .MuiStepLabel-label': { fontSize: '14px' },
            ...stepperSx,
          }}
        >
          {steps.map((step, index) => (
            <Step key={step.label} data-testid={`ingest-stepper`}>
              <CustomButton
                onClick={() => handleStepSelection(step, index)}
                disabled={activeStepIndex <= index}
                aria-label={`Go to step ${index + 1}: ${step.label}`}
                data-testid={`ingest-stepper-step-${index}`}
              >
                <StepLabel>{step.label}</StepLabel>
              </CustomButton>
            </Step>
          ))}
        </Stepper>
      </Box>
      <StepComponent
        nextStep={nextStep}
        previousStep={previousStep}
        activeStepLabel={steps[activeStepIndex].label}
        nextStepLabel={steps[activeStepIndex + 1]?.label}
        previousStepLabel={steps[activeStepIndex - 1]?.label}
        nextStepKey={steps[activeStepIndex + 1]?.key}
        previousStepKey={steps[activeStepIndex - 1]?.key}
      />
    </>
  );
};
