import {
  constSelector,
  GraphQLReturn,
  graphQLSelector,
  useCherreValueLoadable,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useDataValidationFileValidationDataQuery } from './__generated__/useDataValidationFileValidationDataQuery.graphql';

export const $dataValidationFileValidationResultData = graphQLSelector({
  query: graphql`
    query useDataValidationFileValidationDataQuery(
      $params: data_validation_file_validation_data_params!
    ) {
      data_validation_file_validation_data(params: $params) {
        json_data
      }
    }
  ` as GraphQLReturn<useDataValidationFileValidationDataQuery>,
  mapResponse: (res) => res.data_validation_file_validation_data.json_data,
});

export const useDataValidationFileValidationData = (
  owner?: string,
  validation_id?: string | null
) => {
  return useCherreValueLoadable(
    owner && validation_id
      ? $dataValidationFileValidationResultData({
          params: {
            owner,
            validation_id,
          },
        })
      : constSelector([])
  );
};
