import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUnifiedIngestExtractFileMutation } from './__generated__/useUnifiedIngestExtractFileMutation.graphql';
import { useRef } from 'react';
import { useErrorRef } from '@cherre-frontend/ui';

export const useUnifiedIngestExtractFile = () => {
  const ref = useRef<useUnifiedIngestExtractFileMutation['response'] | null>(
    null
  );
  const { errorRef, setError } = useErrorRef();

  const mutation = useMutation(
    graphql`
      mutation useUnifiedIngestExtractFileMutation(
        $params: unified_ingest_extract_file_params!
      ) {
        unified_ingest_extract_file(params: $params) {
          file_id
          owner_name
        }
      }
    ` as GraphQLReturn<useUnifiedIngestExtractFileMutation>,
    {
      onCompleted: (resp) => {
        ref.current = resp;
      },
      onError: (error, ctx) => {
        ctx.showSnackbar({
          message: 'Error extracting the file',
          type: 'error',
        });
        setError(error);
      },
      timeout: false,
    }
  );

  return async (params: useUnifiedIngestExtractFileMutation['variables']) => {
    await mutation(params);
    if (errorRef.current) {
      throw errorRef.current;
    }
    return ref.current?.unified_ingest_extract_file;
  };
};
