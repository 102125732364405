import {
  ArrowBackIcon,
  Button,
  Grid,
  PageContainer,
  ReportIcon,
  Typography,
} from '@cherre-frontend/ui';
import React from 'react';

export type IngestLayoutProps = {
  title: string;
  onCancelUploadClick?: () => void;
  onColumnMappingReferenceGuideClick?: () => void;
  children: React.ReactNode;
};

export const IngestLayout: React.FC<IngestLayoutProps> = ({
  title,
  onCancelUploadClick,
  onColumnMappingReferenceGuideClick,
  children,
}) => {
  return (
    <PageContainer id='IngestLayoutContainer'>
      <Grid
        sx={{
          paddingTop: 1,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '24px',
        }}
      >
        <Button
          style={{ width: 'fit-content' }}
          startIcon={<ArrowBackIcon />}
          onClick={onCancelUploadClick}
        >
          Cancel Upload
        </Button>
        <Grid display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h3'>{title}</Typography>
          {onColumnMappingReferenceGuideClick && (
            <Button
              startIcon={<ReportIcon />}
              onClick={onColumnMappingReferenceGuideClick}
            >
              Dataset Format Guide
            </Button>
          )}
        </Grid>
        <Grid
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {children}
        </Grid>
      </Grid>
    </PageContainer>
  );
};
