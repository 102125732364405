import {
  Box,
  CheckCircleIcon,
  Typography,
  useTheme,
} from '@cherre-frontend/ui';
import React from 'react';
import { useUnifiedIngestGetTransformedDataQuery } from '../../../../../services/__generated__/useUnifiedIngestGetTransformedDataQuery.graphql';

export type ColumnMappingDataPreviewProps = {
  column: string;
  data: useUnifiedIngestGetTransformedDataQuery['response']['unified_ingest_transform_data']['transformed_data'];
};

export const ColumnMappingDataPreview: React.FC<
  ColumnMappingDataPreviewProps
> = ({ column, data }) => {
  const theme = useTheme();
  const preview = data.map((row) => row[column]);

  return (
    <Box>
      {column ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <CheckCircleIcon sx={{ color: theme.palette.primary.main }} />
            <Box>
              <Typography
                sx={{ fontSize: '14px', fontWeight: 700 }}
              >{`Data preview for '${column}' source column`}</Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>{`${
                preview.length
              } total rows, ${
                preview.filter((value) => (value === 0 ? true : Boolean(value)))
                  .length
              } have a value`}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {preview.slice(0, 21).map((value, index) => (
              <Box
                key={index}
                sx={{ display: 'flex', flexDirection: 'row', gap: '24px' }}
              >
                <Typography sx={{ width: '20px' }}>{index + 1}</Typography>
                <Typography>{value}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <CheckCircleIcon sx={{ color: theme.palette.grey[400] }} />
            <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
              Data preview
            </Typography>
          </Box>
          <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
            Hover over a row on the left to view a snapshot of the source data!
          </Typography>
        </Box>
      )}
    </Box>
  );
};
