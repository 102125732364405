import React from 'react';
import {
  Box,
  Radio,
  MuiRadioProps,
  useTheme,
  CheckIcon,
} from '@cherre-frontend/ui';

export type SheetSelectionRadioProps = MuiRadioProps & {
  value: string;
  name: string;
  sheetSelection: string | undefined;
};

export const SheetSelectionRadio: React.FC<SheetSelectionRadioProps> = ({
  name,
  value,
  sheetSelection,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: '4px',
        '&:hover': {
          backgroundColor: '#F4F8FA',
        },
      }}
    >
      <Radio
        sx={{ color: theme.palette.primary.main }}
        label={name}
        value={value}
        formControlLabelProps={{ sx: { width: '100%' } }}
      />
      {sheetSelection === value && (
        <CheckIcon
          sx={{
            height: '20px',
            width: '20px',
            color: theme.palette.success.dark,
          }}
        />
      )}
    </Box>
  );
};
