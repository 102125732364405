import { usePut } from '@cherre-frontend/data-fetching';

export const useUnifiedIngestSendFile = () => {
  const sendFile = usePut('UnifiedIngestSendFile', {
    sendCherreRequestId: false,
    axiosOptions: {
      withCredentials: false,
      headers: {
        'Content-Type': 'application/csv', // TODO: This will be removed
      },
    },
  });

  return sendFile;
};
