/**
 * @generated SignedSource<<9871a3f09a83e916e446cc3b8137c6a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type data_validation_file_validation_data_params = {
  owner: string;
  validation_id: string;
};
export type useDataValidationFileValidationDataQuery$variables = {
  params: data_validation_file_validation_data_params;
};
export type useDataValidationFileValidationDataQuery$data = {
  readonly data_validation_file_validation_data: {
    readonly json_data: ReadonlyArray<any>;
  };
};
export type useDataValidationFileValidationDataQuery = {
  response: useDataValidationFileValidationDataQuery$data;
  variables: useDataValidationFileValidationDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "data_validation_file_validation_data_output",
    "kind": "LinkedField",
    "name": "data_validation_file_validation_data",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "json_data",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDataValidationFileValidationDataQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDataValidationFileValidationDataQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6a34ee641f884ca9c4d2cae404645137",
    "id": null,
    "metadata": {},
    "name": "useDataValidationFileValidationDataQuery",
    "operationKind": "query",
    "text": "query useDataValidationFileValidationDataQuery(\n  $params: data_validation_file_validation_data_params!\n) {\n  data_validation_file_validation_data(params: $params) {\n    json_data\n  }\n}\n"
  }
};
})();

(node as any).hash = "ca92af004c97fe08870fa3626a651f31";

export default node;
