/**
 * @generated SignedSource<<5f7ae125086db6fc94a04edd447ec250>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type unified_ingest_transform_data_params = {
  file_id: string;
  limit: number;
  offset: number;
  owner_name: string;
};
export type useUnifiedIngestGetTransformedDataQuery$variables = {
  params: unified_ingest_transform_data_params;
};
export type useUnifiedIngestGetTransformedDataQuery$data = {
  readonly unified_ingest_transform_data: {
    readonly transformed_data: ReadonlyArray<any>;
  };
};
export type useUnifiedIngestGetTransformedDataQuery = {
  response: useUnifiedIngestGetTransformedDataQuery$data;
  variables: useUnifiedIngestGetTransformedDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "unified_ingest_transform_data_output",
    "kind": "LinkedField",
    "name": "unified_ingest_transform_data",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "transformed_data",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUnifiedIngestGetTransformedDataQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUnifiedIngestGetTransformedDataQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e4b46f75c6165dcad8a6b17419cecdf8",
    "id": null,
    "metadata": {},
    "name": "useUnifiedIngestGetTransformedDataQuery",
    "operationKind": "query",
    "text": "query useUnifiedIngestGetTransformedDataQuery(\n  $params: unified_ingest_transform_data_params!\n) {\n  unified_ingest_transform_data(params: $params) {\n    transformed_data\n  }\n}\n"
  }
};
})();

(node as any).hash = "ba3eeb46341e92ff3b274c89fb93c299";

export default node;
