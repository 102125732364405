import React from 'react';
import {
  IngestionFlowOnCompleteCallback,
  IngestionFlowStepServiceProps,
} from '../../IngestionFlow';
import { IngestSubmitStep } from './IngestSubmitStep';
import { useUnifiedIngestGetTransformedData } from 'src/products/ingestion/services';

export type IngestSubmitStepServiceProps = IngestionFlowStepServiceProps & {
  onComplete: IngestionFlowOnCompleteCallback;
  onCancel: () => void;
};

export const IngestSubmitStepService: React.FC<
  IngestSubmitStepServiceProps
> = ({ flowState, config, onComplete, onCancel }) => {
  const transformedData = useUnifiedIngestGetTransformedData(
    flowState.fileId,
    config.ownerName
  );

  return (
    <IngestSubmitStep
      onClickSubmit={() => {
        if (!flowState.fileId) {
          throw new Error('File ID is required');
        }

        return onComplete(flowState.fileId);
      }}
      validationResults={flowState.validationResults}
      onClickSubmitSibling={onCancel}
      transformedData={transformedData.data ?? []}
      fileId={flowState.fileId}
      ownerName={config.ownerName}
      targetFields={config.targetSchema}
      showOriginalFile={config.templateId !== 'None'}
    />
  );
};
