import React, { useMemo, useRef } from 'react';
import {
  tableBaseStyle,
  Box,
  useTheme,
  useTable,
  Table,
  MoreVertIcon,
  SwapVertIcon,
  TableIcons,
  MRT_ColumnDef,
} from '@cherre-frontend/ui';
import { NoItemsFound } from 'src/products/data-submission-portal/components/NoBatchesFound';
import { capitalize, isEmpty } from 'lodash';
import { IngestionFlowConfig } from '../../../IngestionFlow';

interface ErrorTableProps {
  data?: any[];
  targetFields?: IngestionFlowConfig['targetSchema'];
}

const tableIcons = {
  MoreVertIcon: (props) => <MoreVertIcon {...props} sx={{ color: 'white' }} />,
  SortIcon: (props) => <SwapVertIcon {...props} sx={{ color: 'white' }} />,
} satisfies Partial<TableIcons>;

export const ErrorTable: React.FC<ErrorTableProps> = ({
  data,
  targetFields,
}) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const mandatoryFields = targetFields
    ?.filter((value) => value.mandatory)
    .map((value) => value.name);

  const tableRows = useMemo(() => {
    if (data?.length) {
      const mappedKeys = Object.keys(data[0]);

      return [
        ...data?.map((value) => {
          return Object.assign(
            {},
            ...mappedKeys.map((key) => ({ [key]: value[key] }))
          );
        }),
      ];
    } else {
      return [];
    }
  }, [data?.length]);

  const columnArray = useMemo(() => {
    const rowNumberColumnProperties: MRT_ColumnDef<Record<string, unknown>> = {
      size: 50,
      minSize: 40,
      maxSize: 60,
      header: '',
      enableColumnActions: false,
      muiTableHeadCellProps: {
        sx: {
          height: '36px',
          backgroundColor: theme.palette.secondary.main,
          borderRight: `0.5px ${theme.palette.grey[400]} solid`,
          '*': {
            color: 'white',
            whiteSpace: 'nowrap',
          },
        },
      },
      muiTableBodyCellProps: {
        align: 'center',
        sx: {
          fontWeight: 600,
          backgroundColor: `${theme.palette.secondary.main} !important`,
          color: 'white',
          alignItems: 'center',
        },
      },
    };

    return [
      ...Object.keys(tableRows?.[0] || {}).map((accessorKey) => {
        const columnValues = tableRows.map(
          (value) => value[accessorKey]?.toString() ?? ''
        );
        const maxWidth = columnValues.reduce(
          (prev, current) => Math.max(prev, current.length),
          10
        ) as number;
        const columnWidth = Math.max(maxWidth, accessorKey.length) * 5 + 120;

        return {
          accessorKey: accessorKey,
          header: String(accessorKey).split(/_/g).map(capitalize).join(' '),
          size: columnWidth,
          grow: true,
          ...(accessorKey === 'row_number' ? rowNumberColumnProperties : {}),
        };
      }),
    ];
  }, [tableRows]);

  const table = useTable({
    defaultDisplayColumn: { enableResizing: true },
    layoutMode: 'grid',
    skeletonRowCount: 10,
    skeletonBaseObj: {},
    data: tableRows,
    columns: columnArray,
    muiTableProps: {
      sx: {
        '& .MuiTableRow-root td': {
          backgroundColor: '#fff',
        },
        '& .MuiTableSortLabel-icon': {
          color: 'white',
        },
      },
    },
    enableTopToolbar: false,
    enableToolbarInternalActions: false,
    enableBottomToolbar: false,
    enableTableFooter: false,
    enableColumnResizing: true,
    enableColumnPinning: true,
    enablePagination: false,
    enableDensityToggle: false,
    enableRowActions: false,
    enableRowSelection: false,
    enableColumnActions: true,
    enableColumnFilters: true,
    enableStickyHeader: true,
    columnResizeMode: 'onChange',
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
        border: `0.5px ${theme.palette.grey[400]} solid`,
        maxHeight: 'calc(100vh - 480px)',
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
      },
      ref: tableContainerRef,
    },
    initialState: {
      sorting: [{ id: 'row_number', desc: false }],
      columnPinning: { left: ['row_number'] },
    },
    muiTableBodyCellProps: (props) => {
      return {
        sx: {
          borderRight: `0.5px ${theme.palette.grey[400]} solid`,
          backgroundColor:
            mandatoryFields &&
            mandatoryFields.includes(props.column.id) &&
            isEmpty(props.cell.getValue())
              ? `${theme.accents.error.light} !important`
              : undefined,
        },
      };
    },
    muiTableHeadProps: {
      sx: {
        '& th:nth-of-type(1)': {
          backgroundColor: `${theme.palette.secondary.main} !important`,
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        display: 'flex',
        justifyContent: 'center',
        borderRight: `0.5px ${theme.palette.grey[400]} solid`,
        height: '36px',
        '*': {
          color: 'white',
          whiteSpace: 'nowrap',
        },
      },
    },
    muiTableHeadRowProps: {
      sx: {
        ...tableBaseStyle.muiTableHeadRowProps.sx,
        '& th': {
          color: 'white',
          backgroundColor: theme.palette.secondary.main,
          '& .Mui-TableHeadCell-Content': {
            alignItems: 'center',
          },
        },
      },
    },
    muiTableBodyRowProps: {
      sx: {
        ...tableBaseStyle.muiTableBodyRowProps.sx,
        height: '36px',
      },
    },
    renderColumnActionsMenuItems: ({ internalColumnMenuItems }) => {
      return [...internalColumnMenuItems.splice(4, 4)];
    },
    icons: tableIcons,
    enableSorting: true,
    localization: {
      sortedByColumnAsc: 'Click to sort in descending order.',
      sortedByColumnDesc: 'Click to sort in ascending order.',
      sortByColumnAsc: 'Click to sort in ascending order.',
      sortByColumnDesc: 'Click to sort in descending order.',
    },
    manualSorting: false,
    sortDescFirst: false,
    enableSortingRemoval: false,
    rowVirtualizerOptions: { overscan: 4 },
    renderEmptyRowsFallback: () => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <NoItemsFound title='File has no rows or mapping failed.' />
      </Box>
    ),
  });

  return (
    <Box
      style={{
        display: 'flex',
        flex: 1,
        width: 'fit-content',
        flexDirection: 'column',
      }}
    >
      <Table table={table} />
    </Box>
  );
};
