import React, { useCallback, useMemo, useState } from 'react';
import { IngestLayout } from './components/IngestLayout';
import {
  IngestStepper,
  IngestStepperComponentProps,
  IngestStepperStep,
} from './components/IngestStepper';
import { Panel } from '@cherre-frontend/ui';
import { IngestUploadFileStepService } from './components/IngestUploadFileStep/IngestUploadFileStepService';
import { IngestHeaderSelectionStepService } from './components/IngestHeaderSelectionStep/IngestHeaderSelectionStepService';
import { IngestColumnMappingStepService } from './components/IngestColumnMappingStep/IngestColumnMappingStepService';
import { IngestSubmitStepService } from './components/IngestSubmitStep/IngestSubmitStepService';
import { DatasetFormatModal } from './components/DatasetFormatModal';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';

export type IngestionFlowStepServiceProps = IngestStepperComponentProps & {
  flowState: IngestionFlowState;
  setFlowState: React.Dispatch<React.SetStateAction<IngestionFlowState>>;
  config: IngestionFlowConfig;
};

export type IngestionConfig = { formats: { [k: string]: string } } | undefined;

export type IngestionFlowActiveStepKey =
  | 'upload'
  | 'sheet'
  | 'header'
  | 'mapping'
  | 'submit';

export type IngestionFlowState = {
  signedUrl?: string;
  fileId?: string;
  selectedSheet?: string;
  activeStepKey: IngestionFlowActiveStepKey;
  selectedHeaderRows?: readonly number[];
  validationResults?: {
    validation_id: string;
    checks_summary: {
      failed: boolean;
      failed_rows: number[];
      field: string;
      rule: string;
    }[];
  };
};

export type IngestionFlowConfig = {
  templateId: string;
  ownerName: string;
  datasetName: string;
  datasetId: string;
  performValidation?: boolean;
  targetSchema: {
    label: string;
    name: string;
    mandatory: boolean;
    dataType: string;
  }[];
};

export type IngestionFlowOnCompleteCallback = (
  file_reference_source: string
) => Promise<void> | void;

export type IngestionFlowProps = {
  config: IngestionFlowConfig;
  onCancel: () => void;
  onComplete: IngestionFlowOnCompleteCallback;
  state: IngestionFlowState;
  setState: React.Dispatch<React.SetStateAction<IngestionFlowState>>;
  ingestionConfig: IngestionConfig;
};

const titleMap = {
  upload: {
    title: 'Upload',
  },
  sheet: {
    title: 'Upload',
  },
  header: {
    title: 'Review',
  },
  mapping: {
    title: 'Map',
  },
  submit: {
    title: 'Finalize',
  },
};

export const IngestionFlow: React.FC<IngestionFlowProps> = ({
  config,
  ingestionConfig,
  state,
  setState,
  onComplete,
  onCancel,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isTargetTableCreationEnabled = useFeatureFlag('TargetTableCreation');
  const closeDialog = () => {
    setIsOpen(false);
  };

  const stepTitle = titleMap[state.activeStepKey].title;

  const steps = useMemo(
    () =>
      (
        [
          {
            label: 'Upload File',
            key: 'upload',
            showWarning: true,
            warningTitle: 'Are you sure you want to continue?',
            warningDescription:
              'Do you want to upload a new file? This will replace the current file and all its selections.',
            onWarningContinue: () => {
              setState((s) => ({ ...s, fileId: undefined }));
            },
            component: (props) => (
              <Panel
                id='upload-file-panel'
                style={{ height: '100%', display: 'grid', overflow: 'hidden' }}
              >
                <IngestUploadFileStepService
                  {...props}
                  flowState={state}
                  setFlowState={setState}
                  config={config}
                />
              </Panel>
            ),
          },
          !isTargetTableCreationEnabled
            ? config.templateId === 'None' && {
                label: 'Header Selection',
                key: 'header',
                component: (props) => (
                  <Panel
                    id='upload-header-panel'
                    style={{ height: '100%', overflow: 'hidden' }}
                  >
                    <IngestHeaderSelectionStepService
                      {...props}
                      flowState={state}
                      setFlowState={setState}
                      config={config}
                    />
                  </Panel>
                ),
              }
            : {
                label: 'Header Selection',
                key: 'header',
                component: (props) => (
                  <Panel
                    id='upload-header-panel'
                    style={{ height: '100%', overflow: 'hidden' }}
                  >
                    <IngestHeaderSelectionStepService
                      {...props}
                      flowState={state}
                      setFlowState={setState}
                      config={config}
                    />
                  </Panel>
                ),
              },
          !isTargetTableCreationEnabled
            ? config.templateId === 'None' && {
                label: 'Column Mapping',
                key: 'mapping',
                component: (props) => (
                  <Panel
                    id='upload-mapping-panel'
                    style={{ height: '100%', overflow: 'hidden' }}
                  >
                    <IngestColumnMappingStepService
                      {...props}
                      flowState={state}
                      setFlowState={setState}
                      config={config}
                    />
                  </Panel>
                ),
              }
            : {
                label: 'Column Mapping',
                key: 'mapping',
                component: (props) => (
                  <Panel
                    id='upload-mapping-panel'
                    style={{ height: '100%', overflow: 'hidden' }}
                  >
                    <IngestColumnMappingStepService
                      {...props}
                      flowState={state}
                      setFlowState={setState}
                      config={config}
                    />
                  </Panel>
                ),
              },
          {
            label: 'Submit',
            key: 'submit',
            component: (props) => (
              <Panel
                id='upload-submit-panel'
                style={{ height: '100%', overflow: 'hidden' }}
              >
                <IngestSubmitStepService
                  {...props}
                  flowState={state}
                  setFlowState={setState}
                  config={config}
                  onComplete={onComplete}
                  onCancel={onCancel}
                />
              </Panel>
            ),
          },
        ] satisfies Array<IngestStepperStep | false>
      ).filter(Boolean) as IngestStepperStep[],
    [state, onComplete, onCancel, config]
  );

  const setActiveStepIndex = useCallback(
    (idx: React.SetStateAction<number>) => {
      setState((s) => ({
        ...s,
        activeStepKey:
          typeof idx === 'function'
            ? (steps[
                idx(steps.findIndex((step) => step.key === s.activeStepKey))
              ].key as IngestionFlowActiveStepKey)
            : (steps[idx].key as IngestionFlowActiveStepKey),
      }));
    },
    []
  );

  const activeStepIndex = steps.findIndex((s) => s.key === state.activeStepKey);

  return (
    <IngestLayout
      title={`${stepTitle} ${config.datasetName}`}
      onColumnMappingReferenceGuideClick={() => {
        setIsOpen(true);
      }}
      onCancelUploadClick={onCancel}
    >
      <DatasetFormatModal
        isOpen={isOpen}
        onClose={closeDialog}
        datasetName={config.datasetName}
        ingestionConfig={ingestionConfig}
        targetFields={config.targetSchema}
      />
      <IngestStepper
        steps={steps}
        setActiveStepIndex={setActiveStepIndex}
        activeStepIndex={activeStepIndex}
        containerSx={
          !isTargetTableCreationEnabled
            ? config.templateId === 'None'
              ? undefined
              : {
                  width: '546px',
                  margin: 'auto',
                }
            : undefined
        }
      />
    </IngestLayout>
  );
};
