import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUnifiedIngestSetColumnMappingMutation } from './__generated__/useUnifiedIngestSetColumnMappingMutation.graphql';
import { useErrorRef } from '@cherre-frontend/ui';

export const useUnifiedIngestSetColumnMapping = () => {
  const { errorRef, setError } = useErrorRef();
  const mutation = useMutation(
    graphql`
      mutation useUnifiedIngestSetColumnMappingMutation(
        $params: unified_ingest_column_mapping_set_params!
      ) {
        unified_ingest_column_mapping_set(params: $params) {
          file_id
          owner_name
        }
      }
    ` as GraphQLReturn<useUnifiedIngestSetColumnMappingMutation>,
    {
      onError: (error, ctx) => {
        ctx.showSnackbar({
          message: 'Error setting column mapping',
          type: 'error',
        });
        setError(error);
      },
      timeout: false,
    }
  );

  return async (
    params: useUnifiedIngestSetColumnMappingMutation['variables']
  ) => {
    await mutation(params);
    if (errorRef.current) {
      throw errorRef.current;
    }
    return true;
  };
};
