import {
  constSelector,
  GraphQLReturn,
  graphQLSelector,
  useCherreValueLoadable,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUnifiedIngestExtractWorksheetsQuery } from './__generated__/useUnifiedIngestExtractWorksheetsQuery.graphql';

export const $unifiedIngestExtractWorksheets = graphQLSelector({
  query: graphql`
    query useUnifiedIngestExtractWorksheetsQuery(
      $params: unified_ingest_extract_worksheets_params!
    ) {
      unified_ingest_extract_worksheets(params: $params) {
        file_id
        worksheets
      }
    }
  ` as GraphQLReturn<useUnifiedIngestExtractWorksheetsQuery>,
  mapResponse: (resp) => {
    return resp.unified_ingest_extract_worksheets;
  },
});

export const useUnifiedIngestExtractWorksheets = (
  file_id?: string,
  owner_name?: string
) => {
  const data = useCherreValueLoadable(
    file_id && owner_name
      ? $unifiedIngestExtractWorksheets({
          params: { file_id, owner_name },
        })
      : constSelector(null)
  );

  return {
    worksheets: data.state === 'loading' ? [] : data?.getValue()?.worksheets,
    isLoading: data.state === 'loading',
  };
};
