/**
 * @generated SignedSource<<d3e6ace5dd982d44726652abcc4016e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type unified_ingest_column_mapping_get_params = {
  dataset_id: string;
  file_id: string;
  owner_name: string;
  target_schema: ReadonlyArray<any>;
  template_id?: string | null;
  use_cache: boolean;
};
export type useUnifiedIngestGetColumnMappingQuery$variables = {
  params: unified_ingest_column_mapping_get_params;
};
export type useUnifiedIngestGetColumnMappingQuery$data = {
  readonly unified_ingest_column_mapping_get: {
    readonly mapped_columns: ReadonlyArray<any>;
  };
};
export type useUnifiedIngestGetColumnMappingQuery = {
  response: useUnifiedIngestGetColumnMappingQuery$data;
  variables: useUnifiedIngestGetColumnMappingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "unified_ingest_column_mapping_get_output",
    "kind": "LinkedField",
    "name": "unified_ingest_column_mapping_get",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mapped_columns",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUnifiedIngestGetColumnMappingQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUnifiedIngestGetColumnMappingQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a2583aafc0511a7dfa9ea5685af85e88",
    "id": null,
    "metadata": {},
    "name": "useUnifiedIngestGetColumnMappingQuery",
    "operationKind": "query",
    "text": "query useUnifiedIngestGetColumnMappingQuery(\n  $params: unified_ingest_column_mapping_get_params!\n) {\n  unified_ingest_column_mapping_get(params: $params) {\n    mapped_columns\n  }\n}\n"
  }
};
})();

(node as any).hash = "42eba06c09f777a83e592aae389e313b";

export default node;
