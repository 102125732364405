/**
 * @generated SignedSource<<6b4d7710fc78d33f1afba0e14b271bf7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type unified_ingest_header_get_rows_params = {
  file_id: string;
  owner_name: string;
  template_id?: string | null;
};
export type useUnifiedIngestHeaderGetMutation$variables = {
  params: unified_ingest_header_get_rows_params;
};
export type useUnifiedIngestHeaderGetMutation$data = {
  readonly unified_ingest_header_get_rows: {
    readonly file_id: string;
    readonly header_rows: ReadonlyArray<number>;
    readonly owner_name: string;
  };
};
export type useUnifiedIngestHeaderGetMutation = {
  response: useUnifiedIngestHeaderGetMutation$data;
  variables: useUnifiedIngestHeaderGetMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "unified_ingest_header_get_rows_output",
    "kind": "LinkedField",
    "name": "unified_ingest_header_get_rows",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "file_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "owner_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "header_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUnifiedIngestHeaderGetMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUnifiedIngestHeaderGetMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b238a2622d78555c1e72922893f19f58",
    "id": null,
    "metadata": {},
    "name": "useUnifiedIngestHeaderGetMutation",
    "operationKind": "mutation",
    "text": "mutation useUnifiedIngestHeaderGetMutation(\n  $params: unified_ingest_header_get_rows_params!\n) {\n  unified_ingest_header_get_rows(params: $params) {\n    file_id\n    owner_name\n    header_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "a8fcf02ca97d3e482f7d19d1505f10ec";

export default node;
