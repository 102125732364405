import {
  constSelector,
  GraphQLReturn,
  graphQLSelector,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUnifiedIngestGetTransformedDataQuery } from './__generated__/useUnifiedIngestGetTransformedDataQuery.graphql';

export const $unifiedIngestGetTransformedData = graphQLSelector({
  query: graphql`
    query useUnifiedIngestGetTransformedDataQuery(
      $params: unified_ingest_transform_data_params!
    ) {
      unified_ingest_transform_data(params: $params) {
        transformed_data
      }
    }
  ` as GraphQLReturn<useUnifiedIngestGetTransformedDataQuery>,
  mapResponse: (resp) => {
    return resp.unified_ingest_transform_data.transformed_data;
  },
});

export const useUnifiedIngestGetTransformedData = (
  file_id?: string,
  owner_name?: string
) => {
  const data = useCherreValue(
    file_id && owner_name
      ? $unifiedIngestGetTransformedData({
          params: { file_id, owner_name, limit: 20, offset: 0 },
        })
      : constSelector(null)
  );

  return {
    data,
  };
};
