import React, { useCallback, useMemo, useState } from 'react';
import { IngestionFlowStepServiceProps } from '../../IngestionFlow';
import { IngestColumnMappingStep } from './IngestColumnMappingStep';
import {
  useUnifiedIngestGetColumnMapping,
  useUnifiedIngestGetTransformedData,
  useUnifiedIngestSetColumnMapping,
} from 'src/products/ingestion/services';
import { useDataValidationValidateFile } from 'src/products/ingestion/services/useDataValidationValidateFile';
import { data_validation_checks } from 'src/products/ingestion/services/__generated__/useDataValidationValidateFileMutation.graphql';

export type IngestColumnMappingStepServiceProps = IngestionFlowStepServiceProps;

export const IngestColumnMappingStepService: React.FC<
  IngestColumnMappingStepServiceProps
> = ({ flowState, setFlowState, config, nextStep }) => {
  if (!flowState.fileId) {
    throw new Error('Need to upload a file before mapping columns');
  }

  const setColumnMapping = useUnifiedIngestSetColumnMapping();
  const validateFile = useDataValidationValidateFile();
  const transformedData = useUnifiedIngestGetTransformedData(
    flowState.fileId,
    config.ownerName
  );

  const columnSuggestionsData = useUnifiedIngestGetColumnMapping(
    flowState.fileId,
    config.ownerName,
    config.targetSchema,
    config.datasetId,
    config.templateId === 'None' ? '' : config.templateId,
    true
  );

  const initialColumnMappingState = useMemo(
    () =>
      config.targetSchema.map((target) => {
        const suggestion = columnSuggestionsData?.data?.find(
          (column) => column.targetFilledName === target.name
        );
        return {
          source: suggestion ? suggestion.name : '',
          target: target.name,
          option: suggestion
            ? { title: suggestion.name, group: 'Suggested' }
            : null,
        };
      }),
    [config.targetSchema, columnSuggestionsData.data]
  );

  const [columnMappingState, setColumnMappingState] = useState<
    {
      source: string;
      target: string;
      option: { title: string; group: string } | null;
    }[]
  >(initialColumnMappingState);

  const [dataPreviewColumn, setDataPreviewColumn] = useState('');

  const onColumnMappingContinue = useCallback(async () => {
    if (!flowState.fileId) {
      throw new Error('No file ID found');
    }

    const sourceColumns = Object.keys(
      transformedData.data ? transformedData.data[0] : {}
    );

    const user_mapped_columns = sourceColumns
      .map((source) => {
        const mappedColumn = columnMappingState.find(
          (column) => column.source === source
        );
        if (mappedColumn) {
          return {
            name: source,
            targetFilledName: mappedColumn.target,
          };
        } else {
          return;
        }
      })
      .filter((column) => column !== undefined);

    await setColumnMapping({
      params: {
        file_id: flowState.fileId,
        owner_name: config.ownerName,
        user_mapped_columns,
        dataset_id: config.datasetId,
        use_cache: true,
      },
    });

    if (config.performValidation) {
      const validationResults = await validateFile({
        params: {
          owner: config.ownerName,
          file_id: flowState.fileId,
          checks: config.targetSchema
            .flatMap((field) => {
              if (field.mandatory) {
                return [
                  {
                    rule: 'NOT_NULL',
                    field: field.name,
                  },
                  {
                    rule: 'NOT_EMPTY',
                    field: field.name,
                  },
                ];
              }
            })
            .filter(Boolean) as data_validation_checks[],
        },
      });

      setFlowState((prev) => ({
        ...prev,
        validationResults: {
          validation_id:
            validationResults.data_validation_validate_file.validation_id,
          checks_summary:
            validationResults.data_validation_validate_file.checks_summary.map(
              (check) => {
                return {
                  failed: check.failed,
                  failed_rows: [...check.failed_rows],
                  field: check.field,
                  rule: check.rule,
                };
              }
            ),
        },
      }));
    }
    nextStep?.();
  }, [flowState.fileId, config, transformedData.data, columnMappingState]);

  return (
    <IngestColumnMappingStep
      onClickSubmit={onColumnMappingContinue}
      data={transformedData?.data ? transformedData.data : []}
      dataset={config.datasetName}
      columnMappingState={columnMappingState ?? []}
      setColumnMappingState={setColumnMappingState}
      dataPreviewColumn={dataPreviewColumn}
      setDataPreviewColumn={setDataPreviewColumn}
      targetFields={{
        unified_ingest_schemas: { original: config.targetSchema },
      }}
      columnSuggestions={columnSuggestionsData.data ?? []}
    />
  );
};
