import {
  constSelector,
  GraphQLReturn,
  graphQLSelector,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUnifiedIngestExtractInfoQuery } from './__generated__/useUnifiedIngestExtractInfoQuery.graphql';

const $unifiedIngestExtractInfo = graphQLSelector({
  query: graphql`
    query useUnifiedIngestExtractInfoQuery(
      $params: unified_ingest_extract_info_params!
    ) {
      unified_ingest_extract_info(params: $params) {
        file_id
        owner_name
        number_of_rows
      }
    }
  ` as GraphQLReturn<useUnifiedIngestExtractInfoQuery>,
  mapResponse: (resp) => {
    return resp.unified_ingest_extract_info;
  },
});

export const useUnifiedIngestExtractInfo = (
  file_id?: string,
  owner_name?: string
) => {
  return useCherreValue(
    file_id && owner_name
      ? $unifiedIngestExtractInfo({
          params: { file_id, owner_name },
        })
      : constSelector(null)
  );
};
