import {
  GraphQLReturn,
  useMutationAsync,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useDataValidationValidateFileMutation } from './__generated__/useDataValidationValidateFileMutation.graphql';

export const useDataValidationValidateFile = () => {
  return useMutationAsync(
    graphql`
      mutation useDataValidationValidateFileMutation(
        $params: data_validation_validate_file_params!
      ) {
        data_validation_validate_file(params: $params) {
          validation_id @required(action: THROW)
          checks_summary @required(action: THROW) {
            failed @required(action: THROW)
            failed_rows @required(action: THROW)
            field @required(action: THROW)
            rule @required(action: THROW)
          }
        }
      }
    ` as GraphQLReturn<useDataValidationValidateFileMutation>
  );
};
