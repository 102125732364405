/**
 * @generated SignedSource<<edc54ceb727636fae290074748197338>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type unified_ingest_upload_create_signed_url_params = {
  file_name: string;
  owner_name: string;
};
export type useUnifiedIngestUploadCreateSignedUrlMutation$variables = {
  params: unified_ingest_upload_create_signed_url_params;
};
export type useUnifiedIngestUploadCreateSignedUrlMutation$data = {
  readonly unified_ingest_upload_create_signed_url: {
    readonly file_id: string;
    readonly upload_url: string;
  };
};
export type useUnifiedIngestUploadCreateSignedUrlMutation = {
  response: useUnifiedIngestUploadCreateSignedUrlMutation$data;
  variables: useUnifiedIngestUploadCreateSignedUrlMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "unified_ingest_upload_create_signed_url_output",
    "kind": "LinkedField",
    "name": "unified_ingest_upload_create_signed_url",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "file_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "upload_url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUnifiedIngestUploadCreateSignedUrlMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUnifiedIngestUploadCreateSignedUrlMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d9bdc84b8dbe4c9dcc4b0d1905325136",
    "id": null,
    "metadata": {},
    "name": "useUnifiedIngestUploadCreateSignedUrlMutation",
    "operationKind": "mutation",
    "text": "mutation useUnifiedIngestUploadCreateSignedUrlMutation(\n  $params: unified_ingest_upload_create_signed_url_params!\n) {\n  unified_ingest_upload_create_signed_url(params: $params) {\n    file_id\n    upload_url\n  }\n}\n"
  }
};
})();

(node as any).hash = "03aab839ce5c3c3f32be81e91b9dc436";

export default node;
