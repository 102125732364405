import React, { useMemo, useRef } from 'react';
import {
  tableBaseStyle,
  Box,
  useTheme,
  useTable,
  Table,
  MoreVertIcon,
  SwapVertIcon,
  TableIcons,
  LinearProgress,
} from '@cherre-frontend/ui';
import { NoItemsFound } from 'src/products/data-submission-portal/components/NoBatchesFound';
import { capitalize } from 'lodash';
import { IngestionFlowConfig } from '../../../IngestionFlow';
import { useMeasure } from 'react-use';

interface UploadSheetTableProps {
  loading: boolean;
  tableRows: any[];
  targetFields?: IngestionFlowConfig['targetSchema'];
}

const tableIcons = {
  MoreVertIcon: (props) => <MoreVertIcon {...props} sx={{ color: 'white' }} />,
  SortIcon: (props) => <SwapVertIcon {...props} sx={{ color: 'white' }} />,
} satisfies Partial<TableIcons>;

export const UploadSheetTable: React.FC<UploadSheetTableProps> = ({
  loading,
  tableRows,
  targetFields,
}) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const columnArray = useMemo(
    () => [
      ...Object.keys(tableRows?.[0] || {}).map((accessorKey) => {
        const columnValues = tableRows.map(
          (value) => value[accessorKey]?.toString() ?? ''
        );
        const maxWidth = columnValues.reduce(
          (prev, current) => Math.max(prev, current.length),
          10
        ) as number;
        const columnWidth = Math.max(maxWidth, accessorKey.length) * 5 + 120;

        return {
          accessorKey: accessorKey,
          header: String(accessorKey).split(/_/g).map(capitalize).join(' '),
          size: columnWidth,
          grow: false,
        };
      }),
    ],
    [tableRows]
  );

  const skeletonColumns = useMemo(
    () =>
      targetFields?.map((field) => ({
        accessorKey: field.name,
        header: String(field.label).split(/_/g).map(capitalize).join(' '),
        size: 200,
        grow: false,
      })) ?? [],
    [targetFields]
  );

  const table = useTable({
    defaultDisplayColumn: { enableResizing: true },
    layoutMode: 'grid',
    skeletonRowCount: 10,
    skeletonBaseObj: {},
    data: loading && !tableRows.length ? undefined : tableRows,
    columns: loading && !tableRows.length ? skeletonColumns : columnArray,
    muiTableProps: {
      sx: {
        '& .MuiTableRow-root td': {
          backgroundColor: '#fff',
        },
        '& .MuiTableSortLabel-icon': {
          color: 'white',
        },
      },
    },
    displayColumnDefOptions: {
      'mrt-row-numbers': {
        size: 40,
        minSize: 40,
        maxSize: 50,
        Header: '',
        muiTableHeadCellProps: {
          sx: {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.secondary.main,
            borderRight: `0.5px ${theme.palette.grey[400]} solid`,
          },
        },
        muiTableBodyCellProps: {
          align: 'center',
          sx: {
            fontWeight: 600,
            backgroundColor: `${theme.palette.secondary.main} !important`,
            color: 'white',
            alignItems: 'center',
          },
        },
      },
    },
    enableTopToolbar: false,
    enableToolbarInternalActions: false,
    enableBottomToolbar: false,
    enableTableFooter: false,
    enableColumnResizing: true,
    enableColumnPinning: true,
    enableRowNumbers: true,
    enablePagination: false,
    enableDensityToggle: false,
    enableRowActions: false,
    enableRowSelection: false,
    enableColumnActions: true,
    enableColumnFilters: true,
    enableStickyHeader: true,
    columnResizeMode: 'onChange',
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
        maxHeight: 'calc(100vh - 480px)',
        borderRadius: '10px',
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
      },
      ref: tableContainerRef,
    },
    initialState: {
      columnPinning: { left: ['mrt-row-numbers'], right: [] },
    },
    muiTableBodyCellProps: {
      sx: {
        borderRight: `0.5px ${theme.palette.grey[400]} solid`,
      },
    },
    muiTableHeadProps: {
      sx: {
        '& th:nth-of-type(1)': {
          backgroundColor: `${theme.palette.secondary.main} !important`,
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        display: 'flex',
        justifyContent: 'center',
        borderRight: `0.5px ${theme.palette.grey[400]} solid`,
        height: '36px',
        '*': {
          color: 'white',
          whiteSpace: 'nowrap',
        },
      },
    },
    muiTableHeadRowProps: {
      sx: {
        ...tableBaseStyle.muiTableHeadRowProps.sx,
        '& th': {
          color: 'white',
          backgroundColor: theme.palette.secondary.main,
          '& .Mui-TableHeadCell-Content': {
            alignItems: 'center',
          },
        },
      },
    },
    muiTableBodyRowProps: {
      sx: {
        ...tableBaseStyle.muiTableBodyRowProps.sx,
        height: '36px',
      },
    },
    renderColumnActionsMenuItems: ({ internalColumnMenuItems }) => {
      return [...internalColumnMenuItems.splice(4, 4)];
    },
    icons: tableIcons,
    enableSorting: true,
    localization: {
      sortedByColumnAsc: 'Click to sort in descending order.',
      sortedByColumnDesc: 'Click to sort in ascending order.',
      sortByColumnAsc: 'Click to sort in ascending order.',
      sortByColumnDesc: 'Click to sort in descending order.',
    },
    state: {
      showSkeletons: loading && !tableRows.length,
    },
    manualSorting: false,
    sortDescFirst: false,
    enableSortingRemoval: false,
    rowVirtualizerOptions: { overscan: 4 },
    renderEmptyRowsFallback: () => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <NoItemsFound title='File has no rows or mapping failed.' />
      </Box>
    ),
  });

  const [ref, { width }] = useMeasure<HTMLDivElement>();

  const showProgressbar = loading && !!tableRows.length;

  return (
    <div ref={ref}>
      <Box
        style={{
          display: 'flex',
          flex: 1,
          width: 'fit-content',
          maxWidth: width,
          flexDirection: 'column',
        }}
      >
        <Table table={table} />
        <Box sx={{ width: '100%' }}>
          {showProgressbar && <LinearProgress />}
        </Box>
      </Box>
    </div>
  );
};
