import React from 'react';
import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  CloseIcon,
  useTable,
  styled,
  Table,
  useTheme,
} from '@cherre-frontend/ui';
import { IngestionConfig, IngestionFlowConfig } from '../IngestionFlow';
import { capitalize } from 'lodash';

export const Container = styled(Dialog)`
  .MuiDialog-paper {
    background-color: white;
    padding: 20px 20px 20px 20px;
    border-radius: 10px;
    box-shadow: none;
    max-width: 100%;
  }
`;

const TableContainer = styled('div')`
  padding-top: 20px;
  overflow: auto;
  display: flex;
  flex: 1;
  width: 100%;
`;

export const HeaderGrid = styled(Grid)`
  > .MuiBox-root {
    flex-grow: 1;
  }
`;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  datasetName: string;
  targetFields: IngestionFlowConfig['targetSchema'];
  ingestionConfig: IngestionConfig;
};

export const DatasetFormatModal: React.FC<Props> = ({
  targetFields,
  ingestionConfig,
  isOpen,
  datasetName,
  onClose,
}) => {
  const theme = useTheme();
  const data = targetFields.map((field) => {
    let format = '';
    if (ingestionConfig) {
      if (Object.keys(ingestionConfig).length > 0) {
        const configKeys = Object.keys(ingestionConfig.formats);
        format = configKeys.includes(field.dataType)
          ? ingestionConfig.formats[field.dataType]
          : '';
      }
    }
    return {
      name: field.label,
      required: field.mandatory,
      format: format.toUpperCase(),
      type: field.dataType,
    };
  });

  const columns = [
    {
      accessorKey: 'name',
      header: 'Field Name',
      grow: true,
      size: 200,
      Cell: ({ row }) => (
        <Typography sx={{ fontWeight: 700 }}>{row.original.name}</Typography>
      ),
    },
    {
      accessorKey: 'required',
      header: 'Required',
      size: 100,
      Cell: ({ row }) => {
        const isRequired = row.original.required;
        return (
          <Typography
            sx={{
              color: isRequired ? theme.palette.primary.main : 'black',
              fontWeight: isRequired ? 700 : 500,
            }}
          >
            {isRequired ? 'Yes' : 'No'}
          </Typography>
        );
      },
    },
    {
      accessorKey: 'format',
      header: 'Required Format',
      size: 170,
    },
    {
      accessorKey: 'type',
      header: 'Data Type',
      size: 150,
      Cell: ({ row }) => (
        <Typography>{capitalize(row.original.type)}</Typography>
      ),
    },
  ];

  const table = useTable({
    data: data,
    skeletonRowCount: 5,
    columns,
    enableRowSelection: false,
    enableRowActions: false,
    enableBottomToolbar: false,
    enablePagination: false,
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        borderRadius: '10px',
        maxHeight: '500px',
        flex: 1,
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
        borderRadius: '10px',
        border: `1px ${theme.palette.grey[400]} solid`,
      },
    },
    muiTableBodyProps: {
      sx: {
        '& tr: last-child': {
          '& td: first-child': {
            borderRadius: '0px 0px 0px 8px',
          },
          '& td: last-child': {
            borderRadius: '0px 0px 8px 0px',
          },
        },
      },
    },
    muiTableHeadRowProps: {
      sx: {
        '& th': {
          borderRight: '1px white solid',
          borderBottom: `1px ${theme.palette.grey[400]} solid`,
        },
        '& th:last-child': {
          borderRight: 'none',
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontSize: '14px',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: `1px ${theme.palette.grey[400]} solid`,
      },
    },
    enableStickyHeader: true,
  });

  return (
    <Container open={isOpen} onClose={onClose}>
      <HeaderGrid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
      >
        <Grid item xs>
          <Typography
            variant='h5'
            fontWeight={600}
          >{`${datasetName} Dataset Format`}</Typography>
        </Grid>
        <Grid item xs='auto'>
          <IconButton data-testid='close-button' onClick={onClose} size='small'>
            <CloseIcon />
          </IconButton>
        </Grid>
      </HeaderGrid>
      <Typography
        variant='body2'
        sx={{ paddingBottom: '0px', fontSize: '14px', maxWidth: '650px' }}
      >
        Listed are the standard sample dataset schema definitions including all
        required target fields.
      </Typography>
      <TableContainer>
        <Table table={table} />
      </TableContainer>
    </Container>
  );
};
