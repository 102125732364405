import React, { useMemo } from 'react';
import { Box, PageContent, Typography } from '@cherre-frontend/ui';
import {
  HelpBox,
  ColumnMappingSheet,
  ColumnMappingDataPreview,
} from '../../..';
import { useUnifiedIngestGetColumnMappingQuery } from '../../../../services/__generated__/useUnifiedIngestGetColumnMappingQuery.graphql';
import { useUnifiedIngestGetTransformedDataQuery } from '../../../../services/__generated__/useUnifiedIngestGetTransformedDataQuery.graphql';
import { useUnifiedIngestGetTargetFieldsQuery } from 'src/products/data-submission-portal/pages/upload-dataset-new/queries/__generated__/useUnifiedIngestGetTargetFieldsQuery.graphql';

export type IngestColumnMappingStepProps = {
  onClickSubmit: () => void;
  dataset: string;
  data?: useUnifiedIngestGetTransformedDataQuery['response']['unified_ingest_transform_data']['transformed_data'];
  columnMappingState: {
    source: string;
    target: string;
    option: { title: string; group: string } | null;
  }[];
  setColumnMappingState: React.Dispatch<
    React.SetStateAction<
      {
        source: string;
        target: string;
        option: { title: string; group: string } | null;
      }[]
    >
  >;
  dataPreviewColumn: string;
  setDataPreviewColumn: React.Dispatch<React.SetStateAction<string>>;
  columnSuggestions: useUnifiedIngestGetColumnMappingQuery['response']['unified_ingest_column_mapping_get']['mapped_columns'];
  targetFields?: useUnifiedIngestGetTargetFieldsQuery['response']['sys_property_batch_datasets'][0]['dataset'];
};

export const IngestColumnMappingStep: React.FC<
  IngestColumnMappingStepProps
> = ({
  data,
  dataset,
  columnMappingState,
  setColumnMappingState,
  dataPreviewColumn,
  setDataPreviewColumn,
  targetFields,
  columnSuggestions,
  onClickSubmit,
}) => {
  const isNextDisabled = useMemo(() => {
    if (targetFields) {
      const requiredFields = targetFields.unified_ingest_schemas.original
        .filter((field) => field.mandatory)
        .map((field) => field.name);
      const mappedFields = columnMappingState.filter((row) =>
        requiredFields.includes(row.target)
      );
      return !mappedFields.every((field) => field.source);
    } else {
      return false;
    }
  }, [columnMappingState, targetFields]);

  return (
    <>
      <HelpBox
        title='Set or confirm the column mapping'
        subtitle={
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography>
              {`Configure the column mapping below if needed. Note: All `}
              &#42;
              {` required fields must be mapped to continue. Any non-required fields that aren't mapped will not be included in the target schema mapping.`}
            </Typography>
          </Box>
        }
        helpText="Doesn't seem right? Ask Cherre for help."
        submitText='Next'
        submitDisabled={isNextDisabled}
        onClickHelp={
          window.INTERCOM_APP_ID
            ? () => {
                window.Intercom('showNewMessage');
              }
            : undefined
        }
        onClickSubmit={onClickSubmit}
      />
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
        <PageContent
          sx={{
            width: '100%',
            height: 'fit-content',
            display: 'flex',
          }}
        >
          {data && (
            <ColumnMappingSheet
              data={data ? data : []}
              dataset={dataset}
              columnMappingState={columnMappingState}
              setColumnMappingState={setColumnMappingState}
              setDataPreviewColumn={setDataPreviewColumn}
              targetFields={targetFields}
              columnSuggestions={columnSuggestions}
            />
          )}
        </PageContent>
        <PageContent
          sx={{
            maxWidth: '480px',
            height: 'fit-content',
            paddingBottom: '16px',
          }}
        >
          {data && (
            <ColumnMappingDataPreview
              column={dataPreviewColumn}
              data={data ?? []}
            />
          )}
        </PageContent>
      </Box>
    </>
  );
};
