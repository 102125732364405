export const getHeader = (index: number) => {
  const alphabetRounds = Math.floor(index / 26);
  if (alphabetRounds === 0 || (alphabetRounds === 1 && index % 26 === 0)) {
    return String.fromCharCode(index + 64);
  } else {
    return `${String.fromCharCode(
      (index % 26 === 0 ? alphabetRounds - 1 : alphabetRounds) + 64
    )}${String.fromCharCode((index % 26 === 0 ? 26 : index % 26) + 64)}`;
  }
};
