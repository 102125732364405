/**
 * @generated SignedSource<<6af2e332ead987f7b2bac1751fb5dfa9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type unified_ingest_extract_worksheets_params = {
  file_id: string;
  owner_name: string;
};
export type useUnifiedIngestExtractWorksheetsQuery$variables = {
  params: unified_ingest_extract_worksheets_params;
};
export type useUnifiedIngestExtractWorksheetsQuery$data = {
  readonly unified_ingest_extract_worksheets: {
    readonly file_id: string;
    readonly worksheets: ReadonlyArray<string>;
  };
};
export type useUnifiedIngestExtractWorksheetsQuery = {
  response: useUnifiedIngestExtractWorksheetsQuery$data;
  variables: useUnifiedIngestExtractWorksheetsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "unified_ingest_extract_worksheets_output",
    "kind": "LinkedField",
    "name": "unified_ingest_extract_worksheets",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "file_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "worksheets",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUnifiedIngestExtractWorksheetsQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUnifiedIngestExtractWorksheetsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "70bc98cb05460b9c09486e0574c46df0",
    "id": null,
    "metadata": {},
    "name": "useUnifiedIngestExtractWorksheetsQuery",
    "operationKind": "query",
    "text": "query useUnifiedIngestExtractWorksheetsQuery(\n  $params: unified_ingest_extract_worksheets_params!\n) {\n  unified_ingest_extract_worksheets(params: $params) {\n    file_id\n    worksheets\n  }\n}\n"
  }
};
})();

(node as any).hash = "2172e63b0bf3e320f3f0a4bf48003b5d";

export default node;
