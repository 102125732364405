import {
  constSelector,
  GraphQLReturn,
  graphQLSelector,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUnifiedIngestGetColumnMappingQuery } from './__generated__/useUnifiedIngestGetColumnMappingQuery.graphql';

export const $unifiedIngestGetColumnMappingData = graphQLSelector({
  query: graphql`
    query useUnifiedIngestGetColumnMappingQuery(
      $params: unified_ingest_column_mapping_get_params!
    ) {
      unified_ingest_column_mapping_get(params: $params) {
        mapped_columns
      }
    }
  ` as GraphQLReturn<useUnifiedIngestGetColumnMappingQuery>,
  mapResponse: (resp) => {
    return resp.unified_ingest_column_mapping_get.mapped_columns as unknown as {
      targetFilledName: string;
      name: string;
    }[];
  },
  swr: false,
  resetCache: true,
});

export const useUnifiedIngestGetColumnMapping = (
  file_id?: string,
  owner_name?: string,
  target_schema?: any,
  dataset_id?: string,
  template_id?: string,
  use_cache?: boolean
) => {
  const data = useCherreValue(
    file_id && owner_name && target_schema
      ? $unifiedIngestGetColumnMappingData({
          params: {
            file_id,
            owner_name,
            target_schema,
            dataset_id: dataset_id ?? '',
            template_id: template_id === 'None' ? '' : template_id,
            use_cache: use_cache ?? false,
          },
        })
      : constSelector(null)
  );

  return {
    data,
  };
};
