import React, { useMemo, useCallback, useRef, UIEvent } from 'react';
import {
  Table,
  useTable,
  useTheme,
  Typography,
  MoreVertIcon,
  SwapVertIcon,
  TableIcons,
  Box,
  LinearProgress,
} from '@cherre-frontend/ui';
import { NoItemsFound } from 'src/products/data-submission-portal/components/NoBatchesFound';
import { useUnifiedIngestExtractDataLoadable } from '../../../../../services';
import { getHeader } from '../../../utils';
import { usePrevious } from 'react-use';

export type OriginalFileTableProps = {
  fileId?: string;
  ownerName?: string;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
};

const tableIcons = {
  MoreVertIcon: (props) => <MoreVertIcon {...props} sx={{ color: 'white' }} />,
  SortIcon: (props) => <SwapVertIcon {...props} sx={{ color: 'white' }} />,
} satisfies Partial<TableIcons>;

export const OriginalFileTable: React.FC<OriginalFileTableProps> = ({
  fileId,
  ownerName,
  limit,
  setLimit,
}) => {
  const theme = useTheme();
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const extractedData = useUnifiedIngestExtractDataLoadable(fileId, ownerName, {
    limit,
    offset: 0,
  });

  const prevData = usePrevious(
    extractedData?.state === 'hasValue'
      ? extractedData.valueMaybe()?.json_data ?? []
      : []
  );

  const data =
    extractedData?.state === 'hasValue'
      ? extractedData.valueMaybe()?.json_data ?? []
      : prevData ?? [];

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
        if (scrollHeight - scrollTop - clientHeight < 400) {
          setLimit((prev) => {
            if (prev > data.length) {
              return prev;
            } else {
              return prev + 100;
            }
          });
        }
      }
    },
    [data.length]
  );

  const mappedData = useMemo(
    () =>
      data.map((value) => ({
        ...value.record.reduce((acc, key, i) => {
          acc[getHeader(i + 1)] = value.record[i];
          return acc;
        }, {}),
      })),
    [data]
  );

  const generatedColumns = useMemo(() => {
    const firstRowOrGenerated = data?.[0]?.record ?? new Array(10).fill('');

    const columns = firstRowOrGenerated.map((key, index) => {
      const col = getHeader(index + 1);
      const columnValues = mappedData.map(
        (value) => value[col]?.toString() ?? ''
      );
      const maxWidth = columnValues.reduce(
        (prev, current) => Math.max(prev, current.length),
        10
      ) as number;
      const columnWidth = maxWidth + 100;

      return {
        accessorKey: col,
        header: col,
        size: columnWidth,
        grow: true,
        Cell: ({ row }) => (
          <Typography noWrap sx={{ fontSize: '12px' }}>
            {row.original[col]}
          </Typography>
        ),
        muiTableBodyCellProps: () => ({
          sx: {
            borderRight: `0.5px ${theme.palette.grey[400]} solid`,
            borderBottom: `0.5px ${theme.palette.grey[400]} solid`,
            fontSize: '12px',
          },
        }),
      };
    });

    return [...columns];
  }, [data]);

  const skeletonColumns = useMemo(
    () =>
      new Array(10).fill('').map((field, idx) => ({
        accessorKey: getHeader(idx + 1),
        header: getHeader(idx + 1),
        size: 200,
        grow: true,
      })),
    []
  );

  const table = useTable({
    defaultDisplayColumn: { enableResizing: true },
    skeletonBaseObj: {
      A: 'value',
      B: 'value',
      C: 'value',
      D: 'value',
      E: 'value',
      F: 'value',
      G: 'value',
      H: 'value',
      I: 'value',
      J: 'value',
    },
    data:
      extractedData.state === 'loading' && !data.length
        ? undefined
        : mappedData,
    columns:
      extractedData.state === 'loading' && !data.length
        ? skeletonColumns
        : generatedColumns,
    layoutMode: 'grid',
    skeletonRowCount: 10,
    muiTableProps: {
      sx: {
        '& .MuiTableRow-root td': {
          backgroundColor: '#fff',
        },
        '& .MuiTableSortLabel-icon': {
          color: 'white !important',
        },
      },
    },
    displayColumnDefOptions: {
      'mrt-row-numbers': {
        size: 40,
        minSize: 40,
        maxSize: 50,
        Header: '',
        muiTableHeadCellProps: {
          sx: {
            backgroundColor: theme.palette.grey[500],
            borderRight: `0.5px white solid`,
            '& .MuiDivider-root': {
              borderColor: 'transparent',
            },
          },
        },
        muiTableBodyCellProps: {
          align: 'center',
          sx: {
            fontWeight: 600,
            backgroundColor: 'white',
            color: 'black',
            alignItems: 'center',
            borderRight: `0.5px ${theme.palette.grey[400]} solid`,
            boxShadow: 'none',
          },
        },
      },
    },
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableTableFooter: false,
    enableColumnResizing: true,
    enableColumnPinning: true,
    enableRowNumbers: true,
    enablePagination: false,
    enableDensityToggle: false,
    enableRowActions: false,
    enableRowSelection: false,
    enableColumnActions: true,
    enableColumnFilters: true,
    enableStickyHeader: true,
    columnResizeMode: 'onChange',
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
        border: `0.5px ${theme.palette.grey[400]} solid`,
        maxHeight: 'calc(100vh - 480px)',
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
        height: '100%',
      },
      ref: tableContainerRef,
      onScroll: (event: UIEvent<HTMLDivElement>) =>
        fetchMoreOnBottomReached(event.target as HTMLDivElement),
    },
    initialState: {
      columnPinning: { left: ['mrt-row-numbers'], right: [] },
    },
    muiTableHeadCellProps: {
      sx: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.grey[500],
        borderRight: `0.5px white solid`,
        borderBottom: `0.5px white solid`,
        color: 'white',
        height: '36px',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        backgroundColor: 'white',
      },
    },
    muiTableBodyRowProps: {
      sx: {
        height: '36px',
        backgroundColor: 'white',
      },
    },
    renderColumnActionsMenuItems: ({ internalColumnMenuItems }) => {
      return [...internalColumnMenuItems.splice(4, 4)];
    },
    icons: tableIcons,
    enableSorting: true,
    localization: {
      sortedByColumnAsc: 'Click to sort in descending order.',
      sortedByColumnDesc: 'Click to sort in ascending order.',
      sortByColumnAsc: 'Click to sort in ascending order.',
      sortByColumnDesc: 'Click to sort in descending order.',
    },
    state: {
      showSkeletons: extractedData.state === 'loading' && !data.length,
    },
    manualSorting: false,
    sortDescFirst: false,
    enableSortingRemoval: false,
    renderEmptyRowsFallback: () => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <NoItemsFound title='No rows in the original file.' />
      </Box>
    ),
  });

  const showProgressbar = extractedData.state === 'loading' && !!data.length;

  return (
    <Box
      style={{
        paddingTop: '20px',
        display: 'flex',
        flex: 1,
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <Table table={table} />
      <Box sx={{ width: '100%' }}>{showProgressbar && <LinearProgress />}</Box>
    </Box>
  );
};
