import React, { useCallback } from 'react';
import { IngestionFlowStepServiceProps } from '../../IngestionFlow';
import { IngestHeaderSelectionStep } from './IngestHeaderSelectionStep';
import {
  useUnifiedIngestExtractData,
  useUnifiedIngestHeaderSet,
  useUnifiedIngestTransformFile,
} from 'src/products/ingestion/services';

export type IngestHeaderSelectionStepServiceProps =
  IngestionFlowStepServiceProps;

export const IngestHeaderSelectionStepService: React.FC<
  IngestHeaderSelectionStepServiceProps
> = ({ flowState, setFlowState, config, nextStep }) => {
  if (!flowState.selectedSheet || !flowState.fileId) {
    throw new Error('Need to select a sheet before selecting headers');
  }

  const setHeaderFile = useUnifiedIngestHeaderSet();
  const transformFile = useUnifiedIngestTransformFile();

  const setSelectedHeaderRows: React.Dispatch<
    React.SetStateAction<readonly number[] | undefined>
  > = useCallback(
    (selectedHeaderRows) =>
      setFlowState((prev) => ({
        ...prev,
        selectedHeaderRows:
          typeof selectedHeaderRows === 'function'
            ? selectedHeaderRows(prev.selectedHeaderRows)
            : selectedHeaderRows,
      })),
    [setFlowState]
  );

  const fileData = useUnifiedIngestExtractData(
    flowState.fileId,
    config.ownerName
  );

  const onHeaderSelectionContinue = useCallback(async () => {
    if (!flowState.fileId) {
      throw new Error('No file ID or sheet selected');
    }
    const header_rows =
      flowState.selectedHeaderRows?.map((rowNumer) => rowNumer - 1).sort() ??
      (config.templateId === 'None' ? [0] : []);

    await setHeaderFile({
      params: {
        file_id: flowState.fileId,
        owner_name: config.ownerName,
        header_rows,
      },
    });

    await transformFile({
      params: {
        file_id: flowState.fileId,
        owner_name: config.ownerName,
        template_id: config.templateId === 'None' ? '' : config.templateId,
      },
    });

    nextStep?.();
  }, [flowState]);

  return (
    <IngestHeaderSelectionStep
      fileId={flowState.fileId}
      templateId={config.templateId}
      selectedHeaderRows={flowState.selectedHeaderRows}
      setSelectedHeaderRows={setSelectedHeaderRows}
      onClickSubmit={onHeaderSelectionContinue}
      data={fileData?.json_data}
    />
  );
};
