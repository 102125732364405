/**
 * @generated SignedSource<<f23904ed852e45969df6a5fd8b4f0d8d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type unified_ingest_extract_info_params = {
  file_id: string;
  owner_name: string;
};
export type useUnifiedIngestExtractInfoQuery$variables = {
  params: unified_ingest_extract_info_params;
};
export type useUnifiedIngestExtractInfoQuery$data = {
  readonly unified_ingest_extract_info: {
    readonly file_id: string;
    readonly number_of_rows: number;
    readonly owner_name: string;
  };
};
export type useUnifiedIngestExtractInfoQuery = {
  response: useUnifiedIngestExtractInfoQuery$data;
  variables: useUnifiedIngestExtractInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "params",
        "variableName": "params"
      }
    ],
    "concreteType": "unified_ingest_extract_info_output",
    "kind": "LinkedField",
    "name": "unified_ingest_extract_info",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "file_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "owner_name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "number_of_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUnifiedIngestExtractInfoQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUnifiedIngestExtractInfoQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b77f8edf351b146d5aea4e581cd56d85",
    "id": null,
    "metadata": {},
    "name": "useUnifiedIngestExtractInfoQuery",
    "operationKind": "query",
    "text": "query useUnifiedIngestExtractInfoQuery(\n  $params: unified_ingest_extract_info_params!\n) {\n  unified_ingest_extract_info(params: $params) {\n    file_id\n    owner_name\n    number_of_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "1bb88bf5ae406fac176f65cc2f847100";

export default node;
