import React from 'react';
import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUnifiedIngestHeaderGetMutation } from './__generated__/useUnifiedIngestHeaderGetMutation.graphql';
import { useErrorRef } from '@cherre-frontend/ui';

export const useUnifiedIngestHeaderGet = () => {
  const ref = React.useRef<
    useUnifiedIngestHeaderGetMutation['response'] | null
  >(null);
  const { errorRef, setError } = useErrorRef();
  const mutation = useMutation(
    graphql`
      mutation useUnifiedIngestHeaderGetMutation(
        $params: unified_ingest_header_get_rows_params!
      ) {
        unified_ingest_header_get_rows(params: $params) {
          file_id
          owner_name
          header_rows
        }
      }
    ` as GraphQLReturn<useUnifiedIngestHeaderGetMutation>,
    {
      onCompleted: (resp) => {
        ref.current = resp;
      },
      onError: (error, ctx) => {
        ctx.showSnackbar({
          message: 'Error getting header rows',
          type: 'error',
        });
        setError(error);
      },
      timeout: false,
    }
  );

  return async (params: useUnifiedIngestHeaderGetMutation['variables']) => {
    await mutation(params);
    if (errorRef.current) {
      throw errorRef.current;
    }
    return ref.current?.unified_ingest_header_get_rows;
  };
};
