import React, { useCallback, useMemo } from 'react';
import {
  Table,
  useTable,
  styled,
  useTheme,
  MuiRadio,
  MuiCheckbox,
  Box,
  Typography,
  CircleIcon,
  SquareIcon,
} from '@cherre-frontend/ui';
import { NoItemsFound } from 'src/products/data-submission-portal/components/NoBatchesFound';
import { useIsSuspended } from '@cherre-frontend/data-fetching';
import { getHeader } from '../../../utils';
import { useMeasure } from 'react-use';

const Container = styled('div')`
  padding-top: 20px;
  display: flex;
  flex: 1;
`;
const Checkbox = styled(MuiCheckbox)`
  margin-top: 2px;
  width: 36px;
  height: 36px;
  color: ${(props) => props.theme.palette.primary.main};
  &.checked {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

export type HeaderSelectionTableProps = {
  data:
    | {
        record: string[];
        row_number: number;
      }[]
    | undefined;
  templateId: string | null;
  selectedHeaderRows?: readonly number[];
  setSelectedHeaderRows: React.Dispatch<
    React.SetStateAction<readonly number[] | undefined>
  >;
};

export const HeaderSelectionTable: React.FC<HeaderSelectionTableProps> = ({
  data,
  templateId,
  selectedHeaderRows,
  setSelectedHeaderRows,
}) => {
  const theme = useTheme();

  const onRowRadioSelected = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const rowNumber = parseInt(e.target.value);
      setSelectedHeaderRows([rowNumber]);
    },
    []
  );
  const onRowCheckboxSelected = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const rowNumber = parseInt(e.target.value);
      if (e.target.checked) {
        setSelectedHeaderRows((prev) => [
          ...(prev?.filter((value) => value !== rowNumber) ?? []),
          rowNumber,
        ]);
      } else {
        setSelectedHeaderRows((prev) =>
          prev?.filter((value) => value !== rowNumber)
        );
      }
    },
    []
  );
  const generatedColumns = useMemo(() => {
    const largestRow = data?.reduce((max, current) => {
      return current.record.length > max?.record.length ? current : max;
    }, data?.[0]);
    const largestRowOrGenerated = largestRow?.record ?? new Array(10).fill('');

    const columns =
      largestRowOrGenerated.map((_key, index) => {
        const col = getHeader(index + 1);
        return {
          accessorKey: col,
          header: col,
          size: 200,
          grow: true,
          Cell: ({ row }) => (
            <Typography noWrap sx={{ fontSize: '12px' }}>
              {row.original[col]}
            </Typography>
          ),
          muiTableBodyCellProps: ({ row }) => ({
            sx: {
              backgroundColor: selectedHeaderRows?.includes(row.original.select)
                ? `${theme.palette.grey[100]} !important`
                : 'white',
              fontSize: '12px',
              borderRight: `1px ${theme.palette.grey[400]} solid`,
            },
          }),
        };
      }) ?? [];
    return [
      {
        accessorKey: 'select',
        header: '',
        size: 70,
        Cell: ({ row }) => (
          <Box
            sx={{
              backgroundColor: theme.palette.secondary.main,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box>
              {templateId === 'None' ? (
                <>
                  <CircleIcon
                    sx={{
                      color: 'white',
                      position: 'absolute',
                      margin: '8px 6px 6px',
                    }}
                  />
                  <MuiRadio
                    value={row.original.select}
                    checked={selectedHeaderRows?.includes(row.original.select)}
                    onChange={onRowRadioSelected}
                    sx={{
                      color: theme.palette.primary.main,
                      marginTop: '2px',
                      width: '36px',
                      height: '36px',
                    }}
                  />
                </>
              ) : (
                <>
                  <SquareIcon
                    sx={{
                      color: 'white',
                      position: 'absolute',
                      margin: '8px 6px 6px',
                      padding: '2px',
                    }}
                  />
                  <Checkbox
                    value={row.original.select}
                    checked={selectedHeaderRows?.includes(row.original.select)}
                    onChange={onRowCheckboxSelected}
                  />
                </>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '10.5px',
                borderLeft: `1px ${theme.palette.grey[400]} solid`,
                justifyContent: 'center',
                width: '30px',
                height: '35px',
              }}
            >
              <Typography
                sx={{ fontSize: '14px', fontWeight: 700, color: 'white' }}
              >
                {row.original.select}
              </Typography>
            </Box>
          </Box>
        ),
        muiTableBodyCellProps: {
          sx: {
            backgroundColor: `${theme.palette.secondary.main} !important`,
            padding: '0px',
          },
        },
      },
      ...columns,
    ];
  }, [selectedHeaderRows, data]);

  const mappedData = useMemo(
    () =>
      data?.map((value, index) => ({
        select: index + 1,
        ...value.record.reduce((acc, key, i) => {
          acc[getHeader(i + 1)] = value.record[i];
          return acc;
        }, {}),
      })),
    [data]
  );

  const suspended = useIsSuspended();

  const table = useTable({
    data: suspended ? undefined : mappedData,
    layoutMode: 'grid',
    skeletonRowCount: 10,
    skeletonBaseObj: {
      select: 0,
      A: 'value',
      B: 'value',
      C: 'value',
      D: 'value',
      E: 'value',
      F: 'value',
      G: 'value',
      H: 'value',
      I: 'value',
      J: 'value',
    } as any,
    columns: generatedColumns,
    enableSorting: false,
    enableColumnResizing: true,
    enableRowSelection: false,
    enableRowActions: false,
    enableBottomToolbar: false,
    enablePagination: false,
    enableStickyHeader: true,
    positionActionsColumn: 'last',
    state: {
      showSkeletons: Boolean(suspended),
    },
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        borderRadius: '10px',
        maxHeight: 'calc(100vh - 430px)',
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: theme.palette.secondary.main,
        borderRight: '1px white solid',
        color: 'white',
        '.Mui-TableHeadCell-ResizeHandle-Wrapper': {
          marginRight: '-15px',
        },
      },
    },
    muiTableBodyRowProps: {
      sx: {
        height: '36px',
      },
    },
    initialState: {
      columnPinning: { left: ['select'], right: [] },
    },
    manualPagination: false,
    manualSorting: false,
    getRowId: (row) => row.select?.toString(),
    renderEmptyRowsFallback: () => <NoItemsFound title='No Rows Found' />,
  });

  const [ref, { width }] = useMeasure<HTMLDivElement>();

  return (
    <div ref={ref}>
      <Container
        sx={{
          width: 'fit-content',
          maxWidth: width,
          paddingBottom: '20px',
          paddingTop: '0px',
        }}
      >
        <Table table={table} />
      </Container>
    </div>
  );
};
