import React, { useMemo, useRef, useState, useCallback } from 'react';
import {
  CircularProgress,
  CloudUploadIcon,
  Button,
  Box,
  Typography,
  useTheme,
} from '@cherre-frontend/ui';
import { useIsSuspended } from '@cherre-frontend/data-fetching';

export type DragDropUploadFiles =
  | { blob: string | ArrayBuffer | null; file: File }[]
  | { blob: string | ArrayBuffer | null; file: File };

export type DragDropUploadProps<T> = {
  onComplete: (data: T, fileSizes: number[]) => void;
  onError: (errors: string[]) => void;
  sendFile: (files: DragDropUploadFiles) => Promise<T>;
  multiple?: boolean;
  ButtonHide?: boolean;
  ButtonText?: string;
  SupportedFiles?: string;
  isLoading?: boolean;
};

export function DragDropUpload<T>({
  onComplete,
  sendFile,
  multiple = false,
  ButtonHide = false,
  ButtonText = 'Select File',
  SupportedFiles = 'csv,xls,xlsv,xlsx',
  onError,
  isLoading = false,
}: DragDropUploadProps<T>) {
  const [highlight, setHighlight] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null);
  const [FileName, setFileName] = useState<FileList | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [controller, setController] = useState<AbortController | null>(null);
  const theme = useTheme();
  const isSuspended = useIsSuspended();

  const SupportedFilesTypes = useMemo(
    () =>
      new Set(
        SupportedFiles.split(',').map((item) =>
          String(item).trim().toLowerCase()
        )
      ),
    [SupportedFiles]
  );

  const handleFiles = useCallback(
    async (files: FileList | null, abortController: AbortController) => {
      onError([]);
      if (!files || files.length === 0) {
        return;
      }
      setFileName(files);
      const filesObject: { blob: string | ArrayBuffer | null; file: File }[] =
        [];
      const promises = [...files].map((file: File) => {
        return new Promise<void>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (abortController.signal.aborted) {
              reject(new DOMException('Aborted', 'AbortError'));
              return;
            }
            filesObject.push({ blob: reader.result, file });
            resolve();
          };
          reader.onerror = reject;
          if (
            SupportedFilesTypes.has(
              String(file.name.split('.').pop()).toLowerCase()
            )
          ) {
            reader.readAsDataURL(file);
          } else {
            reject(
              new DOMException(
                `File is not a supported file type. Upload a ${[
                  ...SupportedFilesTypes,
                ]
                  .map((type) => '.' + type.toLowerCase())
                  .join(', ')} file.`,
                'FileError'
              )
            );
          }
        });
      });

      try {
        await Promise.all(promises);
        if (filesObject.length > 0) {
          let ret: T;
          let fileSizes: number[] = [];
          if (multiple) {
            ret = await sendFile(filesObject);
            fileSizes = filesObject.map((file) => file.file.size);
          } else {
            ret = await sendFile(filesObject[0]);
            fileSizes = [filesObject[0].file.size];
          }
          onComplete(ret, fileSizes);
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          onError([error.message]);
        }
        setIsProcessing(false);
      }
    },
    [SupportedFilesTypes, multiple, onComplete, sendFile, onError]
  );

  const handleFileInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      const abortController = new AbortController();
      setController(abortController);
      setIsProcessing(true);
      handleFiles(files, abortController);
    },
    [handleFiles]
  );

  const handleDragEvents = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    setHighlight(true);
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      const files = e.dataTransfer.files;
      const abortController = new AbortController();
      setController(abortController);
      setIsProcessing(true);
      handleFiles(files, abortController);
      setHighlight(false);
    },
    [handleFiles]
  );

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    setHighlight(false);
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleCancel = useCallback(() => {
    if (controller) {
      controller.abort();
      setIsProcessing(false);
      setController(null);
    }
  }, [controller]);

  return (
    <Box
      sx={{
        width: '100vh',
        height: '100%',
        margin: '60px auto',
        display: 'flex',
        alignItems: 'center',
        aspectRatio: '2/1',
        zIndex: 1,
        border: `1px dashed ${theme.palette.grey[400]}`,
        borderRadius: '22px',
        position: 'relative',
        transition:
          'background-color 0.25s ease-in-out 0s, border-color 0.25s ease-in-out 0s',
        overflow: 'hidden',
        backgroundPosition: 'center',
        ...(highlight
          ? { backgroundColor: '#fbe9ef', borderColor: '#df2367' }
          : {}),
        ...(ButtonHide ? { cursor: 'pointer' } : {}),
      }}
      onClick={() => {
        if (ButtonHide) {
          fileInput.current?.click();
        }
      }}
      data-testid='drag-drop-upload'
      onDragEnter={handleDragEvents}
      onDragOver={handleDragEvents}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <Box sx={{ textAlign: 'center', width: '100%' }}>
        {isProcessing || isSuspended || isLoading ? (
          <>
            <Box>
              <CloudUploadIcon
                color='primary'
                sx={{
                  width: '2em',
                  height: '2em',
                }}
              />
            </Box>
            <Typography variant='subtitle2' className='m-0'>
              Processing{' '}
              <Typography
                variantMapping={{ subtitle2: 'span' }}
                variant='subtitle2'
                sx={{ fontWeight: 900, margin: '0 auto' }}
              >
                {[...(FileName || [])].map((item) => item.name).join(', ')}
              </Typography>
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                margin: '15px 0',
              }}
            >
              <CircularProgress size={30} />
            </Box>
            <Button
              onClick={handleCancel}
              sx={{ marginTop: '12px', fontWeight: 600 }}
            >
              Cancel Upload
            </Button>
          </>
        ) : (
          <>
            <Box>
              <CloudUploadIcon
                color='primary'
                sx={{
                  width: '2em',
                  height: '2em',
                }}
              />
            </Box>
            <Typography
              className='mb-0'
              variant='body1'
              sx={{ fontSize: 18, fontWeight: 900, margin: '0 auto' }}
            >
              Drag & Drop to Upload File
            </Typography>
            <Box
              sx={{
                ...{
                  display: 'flex',
                  width: '40%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: theme.palette.grey[500],
                  margin: '6px auto',
                  '& > span': {
                    width: '25%',
                  },
                },
              }}
            >
              <Typography component='span' variant='subtitle2'>
                <hr />
              </Typography>
              <Typography variant='body1' style={{ padding: '0 10px' }}>
                or
              </Typography>
              <Typography component='span' variant='subtitle2'>
                <hr />
              </Typography>
            </Box>
            {!ButtonHide ? (
              <Button
                onClick={() => fileInput.current?.click()}
                variant='contained'
                color='primary'
                sx={{ marginTop: '12px' }}
              >
                {ButtonText}
              </Button>
            ) : null}
            <Typography
              variant='body1'
              className='m-0'
              sx={{ fontSize: 14, margin: '10px 0' }}
            >
              Supports:{' '}
              {[...SupportedFilesTypes]
                .map((type) => type.toUpperCase())
                .join(', ')}
            </Typography>
            <input
              type='file'
              id='media'
              data-testid='drag-drop-upload-file-input'
              style={{ display: 'none' }}
              onChange={handleFileInputChange}
              ref={fileInput}
            />
          </>
        )}
      </Box>
    </Box>
  );
}
