/**
 * @generated SignedSource<<853bb75c52c76c3fa9220d479c8757ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type data_validation_validate_file_params = {
  checks: ReadonlyArray<data_validation_checks>;
  file_id: string;
  owner: string;
  row_number_field?: string | null;
};
export type data_validation_checks = {
  field?: string | null;
  rule?: string | null;
};
export type useDataValidationValidateFileMutation$variables = {
  params: data_validation_validate_file_params;
};
export type useDataValidationValidateFileMutation$data = {
  readonly data_validation_validate_file: {
    readonly checks_summary: ReadonlyArray<{
      readonly failed: boolean;
      readonly failed_rows: ReadonlyArray<number>;
      readonly field: string;
      readonly rule: string;
    }>;
    readonly validation_id: string;
  };
};
export type useDataValidationValidateFileMutation = {
  response: useDataValidationValidateFileMutation$data;
  variables: useDataValidationValidateFileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "params"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "params",
    "variableName": "params"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "validation_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "failed",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "failed_rows",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "field",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rule",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDataValidationValidateFileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "data_validation_validate_file_output",
        "kind": "LinkedField",
        "name": "data_validation_validate_file",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v2/*: any*/),
            "action": "THROW",
            "path": "data_validation_validate_file.validation_id"
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": "data_validation_checks_summary",
              "kind": "LinkedField",
              "name": "checks_summary",
              "plural": true,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": (v3/*: any*/),
                  "action": "THROW",
                  "path": "data_validation_validate_file.checks_summary.failed"
                },
                {
                  "kind": "RequiredField",
                  "field": (v4/*: any*/),
                  "action": "THROW",
                  "path": "data_validation_validate_file.checks_summary.failed_rows"
                },
                {
                  "kind": "RequiredField",
                  "field": (v5/*: any*/),
                  "action": "THROW",
                  "path": "data_validation_validate_file.checks_summary.field"
                },
                {
                  "kind": "RequiredField",
                  "field": (v6/*: any*/),
                  "action": "THROW",
                  "path": "data_validation_validate_file.checks_summary.rule"
                }
              ],
              "storageKey": null
            },
            "action": "THROW",
            "path": "data_validation_validate_file.checks_summary"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDataValidationValidateFileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "data_validation_validate_file_output",
        "kind": "LinkedField",
        "name": "data_validation_validate_file",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "data_validation_checks_summary",
            "kind": "LinkedField",
            "name": "checks_summary",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "91518e2908b7952fc7c4e1736ff7041f",
    "id": null,
    "metadata": {},
    "name": "useDataValidationValidateFileMutation",
    "operationKind": "mutation",
    "text": "mutation useDataValidationValidateFileMutation(\n  $params: data_validation_validate_file_params!\n) {\n  data_validation_validate_file(params: $params) {\n    validation_id\n    checks_summary {\n      failed\n      failed_rows\n      field\n      rule\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0cf799a3459a9d8f2c11f8bb06d6e6fa";

export default node;
