import {
  Alert,
  Box,
  ErrorIcon,
  PageContainer,
  Panel,
  styled,
  Typography,
  CircularProgress,
} from '@cherre-frontend/ui';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';

import Tab from '@mui/material/Tab';

import React, { useMemo, useState } from 'react';
import { UploadSheetTable } from './components/UploadSheetTable';
import { HelpBox } from '../HelpBox';
import { OriginalFileTable } from './components/OriginalFileTable';
import { useUnifiedIngestGetTransformedDataQuery } from 'src/products/ingestion/services/__generated__/useUnifiedIngestGetTransformedDataQuery.graphql';
import { useDataValidationValidateFileMutation } from 'src/products/ingestion/services/__generated__/useDataValidationValidateFileMutation.graphql';
import { ErrorTable } from './components/ErrorTable';
import { useDataValidationFileValidationData } from 'src/products/ingestion/services/useDataValidationFileValidationData';
import { useUnifiedIngestDownloadColumnMappingData } from 'src/products/ingestion/services';
import { usePrevious } from 'react-use';
import { IngestionFlowConfig } from '../../IngestionFlow';

type IngestSubmitProps = {
  onClickSubmitSibling: () => void;
  onClickSubmit: () => void;
  transformedData: useUnifiedIngestGetTransformedDataQuery['response']['unified_ingest_transform_data']['transformed_data'];
  targetFields?: IngestionFlowConfig['targetSchema'];
  fileId?: string;
  ownerName?: string;
  showOriginalFile?: boolean;
  validationResults?: useDataValidationValidateFileMutation['response']['data_validation_validate_file'];
};
enum Tabs {
  ALL_ROWS = '1',
  ERROR_ROWS = '2',
}
export const Icon = styled(ErrorIcon)`
  color: ${({ theme }) => theme.accents.error.main};
`;
const ErrorBanner = styled(Alert)`
  background-color: ${({ theme }) => theme.accents.error.light};
  color: ${({ theme }) => theme.accents.error.main};
  //padding: 6px;
  .MuiAlert-icon {
    padding: 0px;
  }
  .MuiAlert-message {
    padding: 0px;
    font-size: 12px;
    font-weight: bold;
  }
  .MuiAlertTitle-root {
    margin-bottom: 0px;
  }
  .MuiAlert-action {
    padding: 0px;
  }
  .MuiButton-root {
    background-color: white;
  }
`;

export const IngestSubmitStep: React.FC<IngestSubmitProps> = ({
  onClickSubmitSibling,
  onClickSubmit,
  transformedData,
  targetFields,
  ownerName,
  fileId,
  showOriginalFile = true,
  validationResults,
}) => {
  const [originalFileLimit, setOriginalFileLimit] = useState(100);
  const [tabValue, setTabValue] = useState(Tabs.ALL_ROWS);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue as Tabs);
  };

  const showErrorTab = useMemo(() => {
    let showErrorTab = false;
    for (const check of validationResults?.checks_summary ?? []) {
      if (check?.failed) {
        showErrorTab = true;
        setTabValue(Tabs.ERROR_ROWS);
        return showErrorTab;
      }
    }
    return showErrorTab;
  }, []);

  const errorData = useDataValidationFileValidationData(
    ownerName,
    validationResults?.validation_id
  );

  const { data: columnMappingData } = useUnifiedIngestDownloadColumnMappingData(
    fileId,
    ownerName
  );

  const prevData = usePrevious(
    columnMappingData?.state === 'hasValue'
      ? columnMappingData.valueMaybe() ?? []
      : []
  );

  const data =
    columnMappingData?.state === 'hasValue'
      ? columnMappingData.valueMaybe() ?? []
      : prevData ?? [];

  const tableRows = useMemo(() => {
    if (!data.length || !transformedData.length) {
      return [];
    }

    const sortedTargetFields = [...(targetFields ?? [])]
      .sort((a, b) => {
        return a.mandatory === b.mandatory ? 0 : a.mandatory ? -1 : 1;
      })
      .map((field) => field.name);

    const mappedKeys = Object.keys(data[0]);

    const filteredKeys = sortedTargetFields.filter((field) =>
      mappedKeys.includes(field)
    );

    return data.map((value) =>
      Object.fromEntries(filteredKeys.map((key) => [key, value[key]]))
    );
  }, [data, transformedData, targetFields]);

  return (
    <PageContainer id='IngestSubmitContainer'>
      <HelpBox
        submitDisabled={showErrorTab}
        title='Finalize the mapping'
        helpText="Doesn't seem right? Ask Cherre for help."
        submitSiblingText='Cancel'
        submitText='Submit'
        subtitle='If everything looks good, click the Submit button below to upload your file.'
        onClickHelp={
          window.INTERCOM_APP_ID
            ? () => {
                window.Intercom('showNewMessage');
              }
            : undefined
        }
        onClickSubmit={onClickSubmit}
        onClickSubmitSibling={onClickSubmitSibling}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          width: showOriginalFile ? 'calc(100% - 16px)' : '100%',
          height: '100%',
        }}
      >
        {showOriginalFile && (
          <Box
            sx={{
              backgroundColor: 'white',
              padding: '10px 20px',
              borderRadius: '10px',
              marginTop: '10px',
              width: '50%',
            }}
            data-testid='submit'
          >
            <Typography
              variant='subtitle2'
              className='m-0'
              sx={{ fontWeight: 800, paddingTop: '10px' }}
            >
              Your File
            </Typography>
            <Panel
              id='originalFileTablePanel'
              disableParent={false}
              style={{ height: '100%' }}
            >
              <OriginalFileTable
                fileId={fileId}
                ownerName={ownerName}
                limit={originalFileLimit}
                setLimit={setOriginalFileLimit}
              />
            </Panel>
          </Box>
        )}
        <Box
          sx={{
            backgroundColor: 'white',
            padding: '10px 20px',
            borderRadius: '10px',
            marginTop: '10px',
            width: showOriginalFile ? '50%' : '100%',
          }}
          data-testid='submit'
        >
          <Typography
            variant='subtitle2'
            className='m-0'
            sx={{ fontWeight: 800, color: '#6f0b55', paddingTop: '10px' }}
          >
            Mapped Output
          </Typography>
          {showErrorTab ? (
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  aria-label='lab API tabs example'
                >
                  <Tab
                    label={`All rows (${tableRows.length ?? 0})`}
                    value={Tabs.ALL_ROWS}
                  />
                  <Tab
                    label={`Error (${errorData.valueMaybe()?.length ?? 0})`}
                    value={Tabs.ERROR_ROWS}
                  />
                </TabList>
              </Box>
              <TabPanel
                sx={{ paddingX: '0px', paddingY: '16px' }}
                value={Tabs.ALL_ROWS}
              >
                <Panel
                  id='uploadSheetTablePanel'
                  disableParent={false}
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                  }}
                >
                  <UploadSheetTable
                    loading={columnMappingData?.state === 'loading'}
                    targetFields={targetFields}
                    tableRows={tableRows}
                  />
                </Panel>
              </TabPanel>
              <TabPanel
                sx={{ paddingX: '0px', paddingY: '16px' }}
                value={Tabs.ERROR_ROWS}
              >
                <Panel
                  id='uploadSheetTablePanel'
                  disableParent={false}
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                  }}
                >
                  <ErrorBanner
                    severity='error'
                    icon={<Icon />}
                    style={{
                      alignItems: 'center',
                      borderRadius: '10px',
                      marginBottom: '16px',
                    }}
                  >
                    The required fields in the rows highlighted below are
                    missing. Please resolve these errors in your file and
                    re-upload to submit.
                  </ErrorBanner>
                  {errorData.state === 'loading' ? (
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      padding={'16px'}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <ErrorTable
                      data={errorData.valueMaybe()}
                      targetFields={targetFields}
                    />
                  )}
                </Panel>
              </TabPanel>
            </TabContext>
          ) : (
            <Panel
              id='uploadSheetTablePanel'
              disableParent={false}
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <UploadSheetTable
                loading={columnMappingData?.state === 'loading'}
                targetFields={targetFields}
                tableRows={tableRows}
              />
            </Panel>
          )}
        </Box>
      </Box>
    </PageContainer>
  );
};
