import React from 'react';
import {
  Box,
  Button,
  CloudUploadIcon,
  PageContent,
  RadioGroup,
  Typography,
} from '@cherre-frontend/ui';
import { SheetSelectionRadio } from './SheetSelectionRadio';

type Sheet = {
  name: string;
  value: string;
};

export type IngestSheetSelectionStepProps = {
  sheets: Sheet[];
  onCancel: () => void;
  onContinue: (selectedSheet: string) => Promise<void>;
  selectedSheet?: string;
  setSelectedSheet: React.Dispatch<React.SetStateAction<string>>;
};

export const IngestSheetSelectionStep: React.FC<
  IngestSheetSelectionStepProps
> = ({ sheets, onCancel, onContinue, selectedSheet, setSelectedSheet }) => {
  return (
    <PageContent
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        display: 'flex',
      }}
    >
      <Box
        sx={{
          border: '1px dotted grey',
          borderRadius: '10px',
          height: '475px',
          width: '800px',
          marginBottom: '24px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CloudUploadIcon
            sx={{ height: '35px', width: '35px' }}
            color='primary'
          />
          <Typography
            sx={{ fontSize: '20px', fontWeight: 600, marginTop: '16px' }}
          >
            Select the sheet you want to upload:
          </Typography>
          <RadioGroup
            style={{ gap: '5px', marginTop: '12px', width: '100%' }}
            value={selectedSheet}
            onChange={(_, value) => {
              setSelectedSheet(value);
            }}
          >
            {sheets?.map((sheet, index) => (
              <SheetSelectionRadio
                value={sheet.value}
                name={sheet.name}
                sheetSelection={selectedSheet}
                key={`sheet-${sheet.name}-${index}`}
              />
            ))}
          </RadioGroup>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px',
              marginTop: '32px',
            }}
          >
            <Button
              variant='text'
              style={{ fontWeight: 600 }}
              onClick={onCancel}
            >
              Cancel Upload
            </Button>
            <Button
              variant='contained'
              style={{ padding: '4px 24px', fontWeight: 600 }}
              onClick={async () => {
                await onContinue(selectedSheet ?? '');
              }}
              disabled={selectedSheet === ''}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>
    </PageContent>
  );
};
