import { GraphQLReturn, useMutation } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { useUnifiedIngestHeaderSetMutation } from './__generated__/useUnifiedIngestHeaderSetMutation.graphql';

export const useUnifiedIngestHeaderSet = () => {
  return useMutation(
    graphql`
      mutation useUnifiedIngestHeaderSetMutation(
        $params: unified_ingest_header_set_rows_params!
      ) {
        unified_ingest_header_set_rows(params: $params) {
          file_id
          owner_name
        }
      }
    ` as GraphQLReturn<useUnifiedIngestHeaderSetMutation>,
    {}
  );
};
